import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { subscribeToEntityDataStatuses } from 'services/entities';
import { getInvoicesAggregations } from 'services/firebase/invoices';
import { useStoreActions, useStoreState } from 'state';
import { IEntityDataStatuses } from 'types';
import { errorHandler } from 'utils/errors';

const useEntityDataStatuses = () => {
  const { entityId } = useStoreState((state) => state.UserState);
  const {
    setState: setInvoicesState,
    setInvoicesAggregations,
  } = useStoreActions((state) => state.InvoicesState);
  const [
    entityDataStatuses,
    setEntityDataStatuses,
  ] = useState<IEntityDataStatuses>({
    invoicesLastUpdated: '',
    newPaidAndDeletedInvoicesLastUpdated: '',
    receivableAuthorisedInvoicesLastUpdated: '',
  });

  const getInvoicesAggregationsHandler = useCallback(async () => {
    try {
      setInvoicesState(['isLoadingInvoicesAggregations', true]);

      const { data } = await getInvoicesAggregations();

      if (data.data) {
        setInvoicesAggregations(data.data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setInvoicesState(['isLoadingInvoicesAggregations', false]);
    }
  }, [setInvoicesAggregations, setInvoicesState]);

  const debounceAggregationsHandler = useMemo(
    () => debounce(getInvoicesAggregationsHandler, 1000),
    [getInvoicesAggregationsHandler]
  );

  useEffect(() => {
    if (entityId) {
      const unsubscribe = subscribeToEntityDataStatuses(entityId, (data) => {
        if (data) {
          if (
            data?.newPaidAndDeletedInvoicesLastUpdated &&
            !!entityDataStatuses.newPaidAndDeletedInvoicesLastUpdated &&
            data.newPaidAndDeletedInvoicesLastUpdated !==
              entityDataStatuses.newPaidAndDeletedInvoicesLastUpdated
          ) {
            setInvoicesState(['hasInvoicesDataChanged', true]);
          }

          if (
            data?.receivableAuthorisedInvoicesLastUpdated &&
            !!entityDataStatuses.receivableAuthorisedInvoicesLastUpdated &&
            data.receivableAuthorisedInvoicesLastUpdated !==
              entityDataStatuses.receivableAuthorisedInvoicesLastUpdated
          ) {
            setInvoicesState(['hasInvoicesToCollectDataChanged', true]);
          }

          setEntityDataStatuses(data);
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [
    entityDataStatuses.newPaidAndDeletedInvoicesLastUpdated,
    entityDataStatuses.receivableAuthorisedInvoicesLastUpdated,
    entityId,
    setInvoicesState,
  ]);

  useEffect(() => {
    if (entityId) {
      getInvoicesAggregationsHandler();
    }
  }, [entityId, getInvoicesAggregationsHandler]);

  useEffect(() => {
    if (entityId && entityDataStatuses.invoicesLastUpdated) {
      debounceAggregationsHandler();
    }
  }, [
    debounceAggregationsHandler,
    entityDataStatuses.invoicesLastUpdated,
    entityId,
  ]);
};

export default useEntityDataStatuses;
