import { Button, Paragraph, Popup, Row, Title } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';
import { IPastPerformanceItem } from 'types';
import { parseIntoCurrencyStringWithSymbol, parseRate } from 'utils';
import { getValueColor } from '../../utils';

interface IOwnProps {
  record: IPastPerformanceItem;
  onClose: () => void;
}

const InceptionPnlBreakdown: FC<IOwnProps> = ({ record, onClose }) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  return (
    <Popup
      HeaderContent={<Title variant="h2">Initial FX PnL</Title>}
      FooterContent={
        <Row>
          <Button onClick={onClose}>Close</Button>
        </Row>
      }
      onClose={onClose}
    >
      <Paragraph mb>
        Initial PnL is the difference in value using its specific rate and
        system rate on the date when it was accrued:
      </Paragraph>

      <table>
        <thead>
          <th />
          <th>
            <Paragraph variant="bold" textAlign="end">
              FX rate
            </Paragraph>
          </th>
          <th>
            <Paragraph variant="bold" textAlign="end">
              {entityCurrencyCode} value
            </Paragraph>
          </th>
        </thead>
        <tr>
          <td>
            <Paragraph>Using item-specific rate</Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseRate(record.recordRate)}
            </Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseIntoCurrencyStringWithSymbol(
                Math.abs(record.creationDateRecordRateAmount ?? 0),
                currencyByCode(entityCurrencyCode)?.symbol
              )}
            </Paragraph>
          </td>
        </tr>
        <tr>
          <td>
            <Paragraph>Using system rate</Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseRate(1 / (record.creationDateSystemRate ?? 1))}
            </Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseIntoCurrencyStringWithSymbol(
                Math.abs(record.creationDateSystemRateAmount ?? 0),
                currencyByCode(entityCurrencyCode)?.symbol
              )}
            </Paragraph>
          </td>
        </tr>

        <tr>
          <td>
            <Paragraph>Initial PnL</Paragraph>
          </td>
          <td />
          <td>
            <Paragraph
              variant="bold"
              textAlign="end"
              color={getValueColor(record.inceptionPnl)}
            >
              {parseIntoCurrencyStringWithSymbol(
                record.inceptionPnl,
                currencyByCode(entityCurrencyCode)?.symbol
              )}{' '}
              ({((record.inceptionPnlPercentage ?? 0) * 100)?.toPrecision(3)}%)
            </Paragraph>
          </td>
        </tr>
      </table>
    </Popup>
  );
};

export default InceptionPnlBreakdown;
