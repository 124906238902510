import { Row, Icon } from 'components';
import { useStoreState } from 'state';

export const FlagCell: React.FC<{ currencyCode: string }> = ({
  currencyCode,
}) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(currencyCode);

  if (!currency) {
    return null;
  }

  return (
    <Row justifyContent="center" alignSelf="stretch" flex={1}>
      <Icon icon={currency.countryCode} />
    </Row>
  );
};
