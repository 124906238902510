import { TInvoicePageTabs } from '../types/invoices';

export const mapStringToInvoicePageTabs: Record<string, TInvoicePageTabs> = {
  outstanding: 'outstanding',
  paid: 'paid',
  submitted: 'submitted',
  approved: 'approved',
  paymentRun: 'paymentRun',
  receivables: 'receivables',
  received: 'received',
};
