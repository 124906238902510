import { FC, useEffect, useState } from 'react';
import { Col, Title } from 'components';
import { useStoreState } from 'state';
import { HF_GURU_TASKS } from 'types/hfGuru';
import useHfGuru from 'hooks/useHfGuru';
import Placeholder from 'components/shared/Placeholder/Placeholder';
import { useTheme } from 'styled-components';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import CurrencyInfo from './components/CurrencyInfo/CurrencyInfo';
import ContributorsTable from './components/ContributorsTable/ContributorsTable';
import dayjs from 'dayjs';
import { DB_DATE_FORMAT, DB_MONTH_FORMAT } from 'variables';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(utcPlugin);

const Reports: FC = () => {
  const theme = useTheme();
  const { isIntegratedWithXero } = useIntegrationEngine();
  const { invoicesCurrencies: currencyCodes } = useStoreState(
    (state) => state.InvoicesState
  );
  const [activeCurrencyCode, setActiveCurrencyCode] = useState<string>(
    isIntegratedWithXero ? 'all' : currencyCodes[0] ?? ''
  );
  const [activeMonth, setActiveMonth] = useState('');
  const { hfGuruData, updateHfGuruStatus } = useHfGuru();

  useEffect(() => {
    if (
      hfGuruData &&
      !hfGuruData.completed &&
      hfGuruData.statuses?.healthCheck &&
      !hfGuruData.statuses.healthCheck.completed
    ) {
      updateHfGuruStatus(HF_GURU_TASKS.healthCheck, false);
    }
  }, [hfGuruData, updateHfGuruStatus]);

  return (
    <>
      <CurrencyInfo
        activeCurrencyCode={activeCurrencyCode}
        setActiveCurrencyCode={setActiveCurrencyCode}
        currencyCodes={currencyCodes}
        setActiveMonth={setActiveMonth}
      />

      {activeMonth ? (
        <>
          <ContributorsTable
            currencyCode={activeCurrencyCode}
            dateFrom={dayjs(activeMonth, DB_MONTH_FORMAT)
              .utc(true)
              .startOf('month')
              .format(DB_DATE_FORMAT)}
            dateTo={dayjs(activeMonth, DB_MONTH_FORMAT)
              .utc(true)
              .endOf('month')
              .format(DB_DATE_FORMAT)}
          />
        </>
      ) : (
        <Col>
          <Title mb mbValue={theme.spacing.l} variant="h5">
            Invoices
          </Title>

          <Placeholder
            fullWidth
            description="Choose a period on the graph to see the breakdown"
          />
        </Col>
      )}
    </>
  );
};

export default Reports;
