import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import { getSortQueryStringFromTableSort } from 'utils/search';
import InvoicesTableShared from 'components/shared/InvoicesTable/InvoicesTable';
import useUrlValues from 'hooks/useUrlValues';
import { Button, Loader, PermissionsChecker, Row } from 'components';
import { getInvoicesFilters } from 'pages/Invoices/utils';
import { IInvoiceFromSearch } from 'types';
import { SortingRule } from 'react-table';
import { isEqual } from 'lodash';
import { ExposedUseTableProps } from 'components/shared/Table/types';
import useInvoices from 'hooks/useInvoices';
import useDeviceWidth from 'hooks/useDeviceWidth';
import MobileInvoicesList from '../GroupedInvoicesTable/components/ViewDetailsPopup/components/MobileInvoicesList/MobileInvoicesList';
import { IInvoicesTableActions } from './types';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';
import { getCurrencyTotalsFromInvoices } from 'utils/invoices';
import { useHistory } from 'react-router';
import { getPaymentRunPageLink } from 'utils';
import { IPaymentRun } from 'types/paymentRuns';
import { useTheme } from 'styled-components';
import { deletePaymentRunInvoice } from 'services/paymentRuns';
import { errorHandler } from 'utils/errors';
import { useStoreState } from 'state';

interface IOwnProps {
  defaultFilters?: Record<string, string>;
  paymentRun?: IPaymentRun;
  onSelectInvoices?: (invoices: IInvoiceFromSearch[]) => void;
  invoicesTableActionsRef?: React.MutableRefObject<
    IInvoicesTableActions | undefined
  >;
}

const InvoicesTable: FC<IOwnProps> = ({
  defaultFilters,
  paymentRun,
  onSelectInvoices,
  invoicesTableActionsRef,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile } = useDeviceWidth();
  const {
    currency,
    tab,
    search,
    filter,
    dueDateFrom,
    dueDateTo,
    fullyPaidOnDateFrom,
    fullyPaidOnDateTo,
  } = useUrlValues(
    'currency',
    'tab',
    'search',
    'filter',
    'dueDateFrom',
    'dueDateTo',
    'fullyPaidOnDateFrom',
    'fullyPaidOnDateTo'
  );
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const { transferById } = useStoreState((state) => state.TransfersState);

  const [isLoadingFirstInvoicesPage, setIsLoadingFirstInvoicesPage] = useState(
    true
  );
  const [isUpdatingPaymentRun, setIsUpdatingPaymentRun] = useState(false);
  const [sortState, setSortState] = useState<SortingRule<IInvoiceFromSearch>[]>(
    []
  );
  const tableRef = useRef<ExposedUseTableProps<IInvoiceFromSearch>>(null);
  const isSetSortByForPaidTab = useRef(false);

  const unselectInvoice = (invoiceId: string) => {
    const rowId = tableRef.current
      ?.getSelectedFlatRows()
      .find((row) => row.original.id === invoiceId)?.id;
    if (rowId) {
      tableRef?.current?.toggleRowSelected(rowId);
    }
  };

  useEffect(() => {
    if (tableRef.current && tab === 'paid' && !isSetSortByForPaidTab.current) {
      // we want that only on first render
      isSetSortByForPaidTab.current = true;

      tableRef.current.setSortBy([
        {
          id: 'fullyPaidOnDate',
          desc: true,
        },
      ]);
    }
  }, [sortState, tab]);

  const {
    invoices,
    currentPage,
    hasMoreToLoad,
    isLoadingMoreInvoices,
    fetchInvoicesWithSideEffects,
    updateInMemoryInvoices,
  } = useInvoices();

  const removeInvoiceFromPaymentRun = useCallback(
    async (paymentRunInvoiceId: string) => {
      if (paymentRun?.id) {
        try {
          setIsUpdatingPaymentRun(true);
          await deletePaymentRunInvoice({
            paymentRunId: paymentRun.id,
            paymentRunInvoiceId,
            regeneratePaymentRun: false,
          });
          updateInMemoryInvoices((invoices) =>
            invoices.filter((invoice) => invoice.id !== paymentRunInvoiceId)
          );
        } catch (error: any) {
          errorHandler(error);
        } finally {
          setIsUpdatingPaymentRun(false);
        }
      }
    },
    [paymentRun, updateInMemoryInvoices]
  );

  const fetchFirstInvoicesPage = useCallback(async () => {
    if (!tab) {
      return;
    }

    const filters = getInvoicesFilters({
      tab,
      filterName: filter,
      currency,
      dueDateFrom,
      dueDateTo,
      fullyPaidOnDateFrom,
      fullyPaidOnDateTo,
      paymentRunId: paymentRun?.id,
      ...(defaultFilters || {}),
    });
    const sortFields = getSortQueryStringFromTableSort(sortState);

    tableRef.current?.toggleAllRowsSelected(false);

    fetchInvoicesWithSideEffects({
      page: 1,
      searchQuery: search ?? '',
      filters,
      sortFields,
      reset: true,
      // Fetch all invoices at once for the payment run
      size: paymentRun?.instructions.invoiceIds.length,
    }).finally(() => {
      setIsLoadingFirstInvoicesPage(false);
    });
  }, [
    tab,
    filter,
    currency,
    dueDateFrom,
    dueDateTo,
    fullyPaidOnDateFrom,
    fullyPaidOnDateTo,
    paymentRun?.id,
    paymentRun?.instructions.invoiceIds.length,
    defaultFilters,
    sortState,
    fetchInvoicesWithSideEffects,
    search,
  ]);

  useEffect(() => {
    fetchFirstInvoicesPage();
  }, [fetchFirstInvoicesPage]);

  const handleSortChange = useCallback(
    (tableSortState: SortingRule<IInvoiceFromSearch>[]) => {
      if (isEqual(tableSortState, sortState)) {
        return;
      }

      setSortState(tableSortState);
    },
    [sortState]
  );

  const onLoadMoreItems = useCallback(async () => {
    if (isLoadingMoreInvoices) {
      return;
    }

    return fetchInvoicesWithSideEffects({
      page: currentPage + 1,
      searchQuery: search ?? '',
      filters: getInvoicesFilters({
        tab,
        filterName: filter,
        currency,
        dueDateFrom,
        dueDateTo,
        fullyPaidOnDateFrom,
        fullyPaidOnDateTo,
        paymentRunId: paymentRun?.id,
        ...(defaultFilters || {}),
      }),
      sortFields: getSortQueryStringFromTableSort(sortState),
    });
  }, [
    currency,
    currentPage,
    defaultFilters,
    dueDateFrom,
    dueDateTo,
    fetchInvoicesWithSideEffects,
    filter,
    fullyPaidOnDateFrom,
    fullyPaidOnDateTo,
    isLoadingMoreInvoices,
    search,
    sortState,
    tab,
    paymentRun?.id,
  ]);

  useImperativeHandle(
    invoicesTableActionsRef,
    () => ({
      updateInMemoryInvoices,
      fetchFirstInvoicesPage,
    }),
    [fetchFirstInvoicesPage, updateInMemoryInvoices]
  );

  const currencyTotals = getCurrencyTotalsFromInvoices({
    invoices: invoices as IInvoiceFromSearch[],
    rateContractById,
    transferById,
  });

  return (
    <>
      {isLoadingFirstInvoicesPage && <Loader size="large" />}

      {!isLoadingFirstInvoicesPage && (
        <>
          {isMobile ? (
            <MobileInvoicesList
              data={invoices as IInvoiceFromSearch[]}
              withInfiniteLoading={tab !== 'paymentRun'}
              onLoadMoreItems={onLoadMoreItems}
              loadingThreshold={10}
              isLoadingMoreItems={isLoadingMoreInvoices}
              itemsCount={invoices.length}
              hasMoreToLoad={hasMoreToLoad}
              isPaid={tab === 'paid'}
              isSubmitted={tab === 'submitted'}
              onSelectInvoices={onSelectInvoices}
              selectable={tab !== 'paymentRun'}
              currencyTotals={currencyTotals}
              showFooter={
                tab === 'paymentRun' && invoices.length ? true : undefined
              }
              hasPaymentRun={!!paymentRun?.id}
              removeInvoiceFromPaymentRun={removeInvoiceFromPaymentRun}
              isUpdatingPaymentRun={isUpdatingPaymentRun}
            />
          ) : (
            <InvoicesTableShared
              tableRef={tableRef}
              isVirtualized
              data={invoices as IInvoiceFromSearch[]}
              autoResetSelectedRows={false}
              withInfiniteLoading
              onLoadMoreItems={onLoadMoreItems}
              loadingThreshold={10}
              isLoadingMoreItems={isLoadingMoreInvoices}
              itemsCount={invoices.length}
              hasMoreToLoad={hasMoreToLoad}
              updateInMemoryInvoices={updateInMemoryInvoices}
              manualSortBy
              onSort={handleSortChange}
              onSelectInvoices={onSelectInvoices}
              unselectInvoice={unselectInvoice}
              paymentRunId={paymentRun?.id}
              removeInvoiceFromPaymentRun={removeInvoiceFromPaymentRun}
              isUpdatingPaymentRun={isUpdatingPaymentRun}
              onRenderFooterContent={() =>
                tab === 'paymentRun' && !!invoices.length ? (
                  <Row flex={1}>
                    <PermissionsChecker action="update" resource="payment_runs">
                      <Row gap={theme.spacing.m} justifyContent="flex-start">
                        <Button
                          onClick={() =>
                            history.push(
                              getPaymentRunPageLink({
                                step: '1',
                              })
                            )
                          }
                        >
                          Continue
                        </Button>
                      </Row>
                    </PermissionsChecker>

                    <Row flex={1} justifyContent="flex-end">
                      <InvoicesCurrencyTotals
                        title="Totals :"
                        currencyTotals={currencyTotals}
                      />
                    </Row>
                  </Row>
                ) : null
              }
              selectable={tab !== 'paymentRun'}
              showFooter={
                tab === 'paymentRun' && invoices.length ? true : undefined
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default InvoicesTable;
