import { FC, useMemo, useRef, useState } from 'react';
import { IGroupedInvoicesFromSearch, IInvoiceFromSearch } from 'types';
import InvoicesTable from 'pages/Invoices/components/InvoicesTable/InvoicesTable';
import {
  Paragraph,
  PermissionsChecker,
  Popup,
  Row,
  StaleInfo,
  Title,
} from 'components';
import Button from 'components/shared/Button/Button';
import useInvoicesFooterActions from 'components/shared/InvoicesTable/components/InvoicesTableFooterContent/useInvoicesFooterActions';
import { useStoreState } from 'state';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import { getTabNameByKey } from 'pages/Invoices/utils';
import {
  getUpdatedApprovedInvoices,
  getUpdatedSubmittedInvoices,
} from './utils';
import { IInvoicesTableActions } from 'pages/Invoices/components/InvoicesTable/types';

interface IOwnProps {
  groupedInvoices: IGroupedInvoicesFromSearch;
  onClose: () => void;
  tab: string | null;
}

const ViewDetailsPopup: FC<IOwnProps> = ({ groupedInvoices, onClose, tab }) => {
  const [selectedInvoices, setSelectedInvoices] = useState<
    IInvoiceFromSearch[]
  >([]);
  const {
    user,
    userEntity,
    isUserApprover,
    hasApprovalFlow,
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
  } = useStoreState((state) => state.UserState);
  const { isUpdatingPaymentRun } = useStoreState(
    (state) => state.PaymentRunsState
  );
  const requiredNumberOfApprovalsOnUserEntity =
    userEntity.approvalSettings?.requiredNumberOfApprovals || 1;
  const {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();
  const {
    invoicesForApproval,
    invoicesForSubmission,
    invoicesForPayment,
    hasReceivableInvoices,
    onAddToPaymentRun,
  } = useInvoicesFooterActions({
    selectedInvoices,
  });
  const invoicesTableActionsRef = useRef<IInvoicesTableActions>();

  const defaultFilters = useMemo(
    () => ({
      invoiceContactRecipientName: groupedInvoices.key,
    }),
    [groupedInvoices.key]
  );

  const isAddToPaymentRunDisabled =
    hasReceivableInvoices ||
    isUpdatingPaymentRun ||
    (!isAutomationPackageEnabled && !isFxManagementPackageEnabled);

  return (
    <Popup
      width="1400px"
      onClose={onClose}
      HeaderContent={
        <Title variant="h3">
          {getTabNameByKey(tab)}: {groupedInvoices.key}
        </Title>
      }
      FooterContent={
        <Row>
          {tab === 'outstanding' &&
            hasApprovalFlow &&
            !!invoicesForSubmission.length && (
              <>
                <Button
                  mr
                  variant="secondary"
                  onClick={() => {
                    const invoiceIds = invoicesForSubmission.map(
                      (invoice) => invoice.id
                    );
                    updateInvoicesApprovalStatus({
                      invoiceIds,
                      approvalStatus: 'submitted',
                    }).then(() =>
                      invoicesTableActionsRef.current?.updateInMemoryInvoices(
                        (invoices) =>
                          getUpdatedSubmittedInvoices({
                            invoices,
                            invoicesForSubmission,
                          })
                      )
                    );
                  }}
                  disabled={isUpdatingInvoicesApprovalStatus}
                  isLoading={isUpdatingInvoicesApprovalStatus}
                >
                  {`Submit ${invoicesForSubmission.length} selected`}
                </Button>
              </>
            )}

          {tab === 'submitted' &&
            hasApprovalFlow &&
            isUserApprover &&
            !!invoicesForApproval.length && (
              <Button
                mr
                variant="secondary"
                onClick={() => {
                  const invoiceIds = invoicesForApproval.map(
                    (invoice) => invoice.id
                  );

                  updateInvoicesApprovalStatus({
                    invoiceIds,
                    approvalStatus: 'approved',
                  }).then(() =>
                    invoicesTableActionsRef.current?.updateInMemoryInvoices(
                      (invoices) =>
                        getUpdatedApprovedInvoices({
                          invoices,
                          invoicesForApproval,
                          requiredNumberOfApprovalsOnUserEntity,
                          userId: user?.id,
                        })
                    )
                  );
                }}
                disabled={isUpdatingInvoicesApprovalStatus}
                isLoading={isUpdatingInvoicesApprovalStatus}
              >
                {`Approve ${invoicesForApproval.length} selected`}
              </Button>
            )}

          {!!invoicesForPayment.length && (
            <PermissionsChecker action="update" resource="payment_runs">
              <StaleInfo
                mode="hover"
                strategy="fixed"
                portal
                placement="top"
                infoSize="auto"
                trigger={
                  <Button
                    onClick={onAddToPaymentRun}
                    disabled={isAddToPaymentRunDisabled}
                    isLoading={isUpdatingPaymentRun}
                  >
                    Add to draft run
                  </Button>
                }
              >
                {isAddToPaymentRunDisabled && (
                  <Paragraph color="white">
                    Payment automation package not available. Please check you
                    have an active subscription.
                  </Paragraph>
                )}
              </StaleInfo>
            </PermissionsChecker>
          )}
        </Row>
      }
    >
      <InvoicesTable
        defaultFilters={defaultFilters}
        onSelectInvoices={setSelectedInvoices}
        invoicesTableActionsRef={invoicesTableActionsRef}
      />
    </Popup>
  );
};

export default ViewDetailsPopup;
