import { ParagraphWithEllipsis } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';
import { IPastPerformanceItem } from 'types';

interface IOwnProps {
  record: IPastPerformanceItem;
}

const CounterPartyCell: FC<IOwnProps> = ({ record }) => {
  const { recipientById } = useStoreState((state) => state.RecipientsState);

  if (!record.counterParty) {
    return null;
  }

  if (record.recordType === 'bankTransfer') {
    return (
      <ParagraphWithEllipsis title={record.counterParty}>
        {record.counterParty}
      </ParagraphWithEllipsis>
    );
  }

  const recipient = recipientById(record.counterParty);

  if (recipient) {
    return (
      <ParagraphWithEllipsis title={recipient.recipientName}>
        {recipient.recipientName}
      </ParagraphWithEllipsis>
    );
  }

  return null;
};

export default CounterPartyCell;
