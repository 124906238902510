import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import { IPaymentRunCurrencyTotal, IPaymentRun } from 'types/paymentRuns';
import { openQuery, Notify } from 'utils';
import { ERROR_MESSAGES } from 'variables';
import firestore from './firestore';

interface UpdatePaymentRunItemSummaryParams {
  paymentRunId: string;
  summaryItemId: string;
  buyFx: boolean;
}

export const updatePaymentRunItemSummary = async ({
  paymentRunId,
  summaryItemId,
  buyFx,
}: UpdatePaymentRunItemSummaryParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/summary/${summaryItemId}`,
    {
      buyFx,
    }
  );

interface UpdatePaymentRunTransferParams {
  paymentRunId: string;
  paymentRunTransferId: string;
  reference: string;
}

export const updatePaymentRunTransfer = async ({
  paymentRunId,
  paymentRunTransferId,
  reference,
}: UpdatePaymentRunTransferParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/transfers/${paymentRunTransferId}`,
    {
      reference,
    }
  );
interface DeletePaymentRunTransferParams {
  paymentRunId: string;
  paymentRunTransferId: string;
}

export const deletePaymentRunTransfer = async ({
  paymentRunId,
  paymentRunTransferId,
}: DeletePaymentRunTransferParams) =>
  AxiosPrivateFirebaseInstance.delete<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/transfers/${paymentRunTransferId}`
  );

interface DeletePaymentRunInvoiceParams {
  paymentRunId: string;
  paymentRunInvoiceId: string;
  regeneratePaymentRun: boolean;
}

export const deletePaymentRunInvoice = async ({
  paymentRunId,
  paymentRunInvoiceId,
  regeneratePaymentRun,
}: DeletePaymentRunInvoiceParams) =>
  AxiosPrivateFirebaseInstance.delete<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/invoices/${paymentRunInvoiceId}/${regeneratePaymentRun}`
  );

interface UpdatePaymentRunParams {
  paymentRunId: string;
  paymentDate?: string;
  combine?: boolean;
}

export const updatePaymentRun = async ({
  paymentRunId,
  paymentDate,
  combine,
}: UpdatePaymentRunParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}`,
    {
      paymentDate,
      combine,
    }
  );

export const getChallengeForPaymentRun = async (paymentRunId: string) => {
  const { data } = await AxiosPrivateFirebaseInstance.get<IResponse<string>>(
    `/payment_runs/${paymentRunId}/challenge`
  );

  if (!data.success) {
    Notify.error(
      data.message ||
        ERROR_MESSAGES.somethingWentWrong(' getting the challenge')
    );
    throw new Error(data.message);
  }

  return data.data;
};

interface ConfirmPaymentRunParams {
  paymentRunId: string;
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  openBanking?: boolean;
  challenge: string;
}

export const confirmPaymentRun = async ({
  paymentRunId,
  paymentRunTotals,
  challenge,
  openBanking = false,
}: ConfirmPaymentRunParams) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/confirm/${openBanking}`,
    {
      paymentRunTotals,
      challenge,
    }
  );

interface GetDraftPaymentRunParams {
  entityId: string;
  generate?: boolean;
  openBanking?: boolean;
}

/** Returns existing draft payment run or creates new draft payment run if it does not exist */
export const getDraftPaymentRun = async ({
  entityId,
  generate = false,
  openBanking = false,
}: GetDraftPaymentRunParams) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IPaymentRun>>(
    `/payment_runs/entity/${entityId}/${generate}/${openBanking}`
  );

interface AddInvoicesToPaymentRunParams {
  paymentRunId: string;
  invoiceIds: string[];
}

export const addInvoicesToPaymentRun = async ({
  paymentRunId,
  invoiceIds,
}: AddInvoicesToPaymentRunParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/invoices`,
    {
      invoiceIdsToAdd: invoiceIds,
    }
  );

export const getPaymentRunInvoicesAsCSVFile = async (paymentRunId: string) =>
  AxiosPrivateFirebaseInstance.get<string>(
    `/payment_runs/${paymentRunId}/invoices`
  );

export const getPaymentRunReviewAsCSVFile = async (paymentRunId: string) =>
  AxiosPrivateFirebaseInstance.get<string>(
    `/payment_runs/${paymentRunId}/review`
  );

interface SubscribeToDraftPaymentRunParams {
  entityId: string;
  callback: (paymentRun: IPaymentRun) => void;
}

export const subscribeToDraftPaymentRun = ({
  entityId,
  callback,
}: SubscribeToDraftPaymentRunParams) =>
  firestore
    .collection('paymentRuns')
    .where('_owner', '==', entityId)
    .where('status', '==', 'draft')
    .onSnapshot(
      (query) => {
        callback(openQuery(query)[0]);
      },
      (error) => {
        console.warn(
          'Failed to subscribe to draft payment run. Error: ',
          error
        );
      }
    );
