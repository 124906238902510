import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Icon, Paragraph, Row } from 'components';

import { IPaymentRunItemSummary } from 'types/paymentRuns';
import AuthoriseTransfersCell from 'pages/PaymentRun/components/AuthoriseTransfersCell/AuthoriseTransfersCell';
import AuthoriseGBPCostCell from 'pages/PaymentRun/components/AuthoriseGBPCostCell/AuthoriseGBPCostCell';
import BuyFxCell from 'pages/PaymentRun/components/BuyFxCell/BuyFxCell';
import { useStoreState } from 'state';
import PaymentFeesCell from 'pages/PaymentRun/components/PaymentFeesCell/PaymentFeesCell';
import AuthoriseAmountDueCell from 'pages/PaymentRun/components/AuthoriseAmountDueCell/AuthoriseAmountDueCell';
import { Row as TableRow } from 'react-table';
import ConfirmationIconCell from 'pages/PaymentRun/components/ConfirmationIconCell/ConfirmationIconCell';
import { MobileInvoiceCard } from 'components/shared/MobileInvoiceCard/MobileInvoiceCard.styles';

interface IOwnProps {
  paymentRunItemSummary: IPaymentRunItemSummary;
  onEdit?: (
    recordId: string,
    updatedData: IPaymentRunItemSummary
  ) => Promise<void>;
  isLoadingBuyFxAll?: boolean;
  paymentDate: string;
}

const MobilePaymentRunItemSummaryTile: FC<IOwnProps> = ({
  paymentRunItemSummary,
  onEdit,
  isLoadingBuyFxAll,
  paymentDate,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const invoicesCurrency = currencyByCode(
    paymentRunItemSummary.invoicesCurrency
  );
  const rowObject = {
    original: paymentRunItemSummary,
  } as TableRow<IPaymentRunItemSummary>;

  return (
    <>
      <MobileInvoiceCard
        flex={1}
        padding={theme.spacing.m}
        gap={theme.spacing.m}
      >
        <Row flex={1}>
          <Row gap={theme.spacing.m}>
            {invoicesCurrency && <Icon icon={invoicesCurrency.countryCode} />}
            <AuthoriseTransfersCell record={paymentRunItemSummary} />
          </Row>

          <Row gap={theme.spacing.xxs}>
            {!!onEdit && (
              <>
                <Paragraph variant="bold">Buy FX</Paragraph>
                <BuyFxCell
                  withIcon={false}
                  value={paymentRunItemSummary.buyFx}
                  row={rowObject}
                  onEdit={onEdit}
                  isLoadingBuyFxAll={!!isLoadingBuyFxAll}
                  paymentDate={paymentDate}
                />
              </>
            )}

            {!onEdit && <ConfirmationIconCell row={rowObject} />}
          </Row>
        </Row>

        <Row>
          <AuthoriseAmountDueCell
            totalAmount={paymentRunItemSummary.invoicesAmountDue}
            currencyCode={paymentRunItemSummary.invoicesCurrency}
          />
        </Row>

        <Row>
          <AuthoriseGBPCostCell
            withTitle
            amount={paymentRunItemSummary.paymentTotalAmountInLocalCurrency}
            rate={paymentRunItemSummary.rate}
            currencyCode={paymentRunItemSummary.localCurrency}
            isRateIndicative={!paymentRunItemSummary.buyFx}
            isPrebooked={paymentRunItemSummary.prebooked}
          />

          {paymentRunItemSummary.paymentFeesAmount !== 0 && (
            <PaymentFeesCell
              withTitle
              paymentFeesAmount={paymentRunItemSummary.paymentFeesAmount}
              paymentCurrency={paymentRunItemSummary.paymentCurrency}
            />
          )}
        </Row>
      </MobileInvoiceCard>
    </>
  );
};

export default MobilePaymentRunItemSummaryTile;
