import { FC } from 'react';
import { ParagraphWithEllipsis, Row } from 'components';
import { useTheme } from 'styled-components';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import ContactReviewStatus from 'components/shared/ContactReviewStatus/ContactReviewStatus';
import { IGroupedInvoicesFromSearch } from 'types';
import { useStoreState } from 'state';
import { getPartialRecipientFromGroupedInvoicesFromSearch } from '../../utils';
import useDeviceWidth from 'hooks/useDeviceWidth';

interface OwnProps {
  value: string;
  docsCount: number;
  invoices: IGroupedInvoicesFromSearch['results'];
}

const GroupedContactCell: FC<OwnProps> = ({ value, docsCount, invoices }) => {
  const theme = useTheme();
  const { isMobile } = useDeviceWidth();
  const { recipientByName, recipientById } = useStoreState(
    (state) => state.RecipientsState
  );
  const recipientToUse = getPartialRecipientFromGroupedInvoicesFromSearch({
    recipientName: value,
    invoices,
    recipientByName,
    recipientById,
  });

  return (
    <Row gap={theme.spacing.m}>
      <Row gap={theme.spacing.m}>
        <ParagraphWithEllipsis maxWidth={isMobile ? '120px' : '200px'}>
          {value}
        </ParagraphWithEllipsis>
        <NumberCircle value={docsCount} />
      </Row>

      {!!recipientToUse && <ContactReviewStatus contact={recipientToUse} />}
    </Row>
  );
};

export default GroupedContactCell;
