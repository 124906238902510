import { Paragraph, StaleInfo } from 'components';
import { Row } from 'components/shared/Row/Row';
import { CellProps, Column } from 'react-table';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import { IFundingToCollect } from 'types/funding';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import { useTheme } from 'styled-components';
import { FlagCell } from 'components/shared/FlagCell/FlagCell';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'variables';
import Link from 'components/shared/Link/Link';
import { getInvoiceDetailsLink } from 'utils';

interface IOwnProps {
  setFundingToMatch: React.Dispatch<
    React.SetStateAction<IFundingToCollect | null>
  >;
}

export const generateTableColumns = ({
  setFundingToMatch,
}: IOwnProps): Column<IFundingToCollect>[] => [
  {
    accessor: 'paymentDate',
    Header: 'Payment Date',
    Cell: ({ value }: CellProps<IFundingToCollect>) => (
      <Paragraph>{dayjs(value).format(DATE_FORMAT)}</Paragraph>
    ),
    width: 120,
    minWidth: 100,
  },
  {
    accessor: 'senderName',
    Header: 'Sender Name',
    Cell: ({ value }: CellProps<IFundingToCollect>) => (
      <Paragraph>{value}</Paragraph>
    ),
    width: 120,
    minWidth: 100,
  },
  {
    accessor: 'currency',
    Header: 'Currency',
    Cell: ({ row }: CellProps<IFundingToCollect>) => {
      const theme = useTheme();
      return (
        <Row gap={theme.spacing.xs}>
          <FlagCell currencyCode={row.original.currency} />
          <Paragraph>{row.original.currency}</Paragraph>
        </Row>
      );
    },
    width: 120,
    minWidth: 100,
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    Cell: ({ value, row }: CellProps<IFundingToCollect>) => (
      <AmountCell amount={value} currencyCode={row.original.currency} />
    ),
    width: 120,
    minWidth: 100,
  },
  {
    disableSortBy: true,
    accessor: 'paymentRef',
    Header: 'Reference',
    Cell: ({ value }: CellProps<IFundingToCollect>) => (
      <Paragraph>{value}</Paragraph>
    ),
    width: 120,
    minWidth: 100,
  },
  {
    disableSortBy: true,
    accessor: 'matchedInvoiceIds',
    Header: 'Matched',
    Cell: ({ value }: CellProps<IFundingToCollect>) => {
      if (!value?.length) {
        return null;
      }

      return (
        <Row justifyContent="flex-start">
          <Paragraph mr>Matched Invoices</Paragraph>
          <StaleInfo
            infoSize="24px"
            mode="click"
            strategy="fixed"
            portal
            placement="top"
          >
            {value?.map((invoiceId: string, i: number) => (
              <Link
                color="white"
                key={invoiceId}
                href={getInvoiceDetailsLink({ invoiceId })}
              >
                {i + 1}. {invoiceId}
              </Link>
            ))}
          </StaleInfo>
        </Row>
      );
    },
    width: 120,
    minWidth: 100,
  },
  {
    id: 'action',
    disableSortBy: true,
    Header: () => null,
    Cell: ({ row }: CellProps<IFundingToCollect>) => {
      return (
        <Row flex={1} justifyContent="flex-end">
          <ActionButton
            disabled={row.original.matched}
            onClick={() => setFundingToMatch(row.original)}
          >
            Match
          </ActionButton>
        </Row>
      );
    },
    width: 100,
    filter: 'direction',
  },
];
