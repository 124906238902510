import { action, Action, Computed, computed, thunk, Thunk } from 'easy-peasy';
import { getFeatureFlags } from 'services/featureFlags';
import { TFeatureFlagName, IFeatureFlagsGlobal } from 'types/featureFlags';
import { errorHandler } from 'utils/errors';

export interface FeatureFlagsStateModel {
  isLoadingFeatureFlags: boolean;
  featureFlags: IFeatureFlagsGlobal;
  getFeatureFlags: Thunk<FeatureFlagsStateModel>;
  setFeatureFlags: Action<FeatureFlagsStateModel, IFeatureFlagsGlobal>;
  featureFlagById: Computed<
    FeatureFlagsStateModel,
    (featureFlagId: TFeatureFlagName) => boolean
  >;
}

export const FeatureFlagsState: FeatureFlagsStateModel = {
  isLoadingFeatureFlags: false,
  featureFlags: {
    backtester: false,
    permit: false,
    multipleEntityCurrencies: false,
    msDynamicsStopAtStep3: false,
    openBanking: false,
    openBankingPaymentsForBulkPayments: false,
    showInvoicesToCollect: false,
  },
  setFeatureFlags: action((state, payload) => {
    state.featureFlags = payload;
  }),
  getFeatureFlags: thunk(async ({ setFeatureFlags }) => {
    try {
      const { data } = await getFeatureFlags();

      if (data.data) {
        setFeatureFlags(data.data);
      }
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  featureFlagById: computed(
    [(state) => state.featureFlags],
    (featureFlags) => (featureFlagId) => featureFlags[featureFlagId]
  ),
};
