import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  Button,
  Paragraph,
  ParagraphWithEllipsis,
  PermissionsChecker,
  Row,
  StaleInfo,
} from 'components';

import { IPaymentRunBreakdownByRecipient } from 'types/paymentRuns';
import { RecipientBreakdownMethodWrapper } from '../RecipientBreakdownMethod/RecipientBreakdownMethod.styles';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import MobileInvoicesPopup from '../MobileInvoicesPopup/MobileInvoicesPopup';
import { MobileInvoiceCard } from 'components/shared/MobileInvoiceCard/MobileInvoiceCard.styles';

interface IOwnProps {
  breakdownByRecipient: IPaymentRunBreakdownByRecipient;
  isUpdatingPaymentRun?: boolean;
  hasPaymentRun?: boolean;
  onRemoveInvoice: (paymentRunInvoiceId: string) => Promise<void>;
  onRemoveTransfer: (paymentRunTransferId: string) => Promise<void>;
}

const MobileRecipientBreakdownTile: FC<IOwnProps> = ({
  breakdownByRecipient,
  isUpdatingPaymentRun,
  onRemoveInvoice,
  onRemoveTransfer,
}) => {
  const theme = useTheme();
  const [showInvoicesPopup, setShowInvoicesPopup] = useState(false);

  return (
    <>
      <MobileInvoiceCard
        flex={1}
        padding={theme.spacing.m}
        gap={theme.spacing.m}
      >
        <Row>
          <Row flex={1}>
            <Row>
              <ParagraphWithEllipsis
                maxWidth="150px"
                mr
                mrValue={theme.spacing.xs}
              >
                {breakdownByRecipient.recipientName}
              </ParagraphWithEllipsis>

              <Paragraph variant="bold" color="grey">
                {breakdownByRecipient.invoices.length}
              </Paragraph>
              {!breakdownByRecipient.valid && (
                <StaleInfo strategy="fixed" mode="click" placement="top" portal>
                  <Paragraph color="white">
                    {breakdownByRecipient.error}
                  </Paragraph>
                </StaleInfo>
              )}
            </Row>

            <RecipientBreakdownMethodWrapper>
              <Paragraph color="coral">
                {breakdownByRecipient.method.toUpperCase()}
              </Paragraph>
            </RecipientBreakdownMethodWrapper>
          </Row>

          <PermissionsChecker action="update" resource="payment_runs">
            <Button
              variant="link"
              icon="remove-ico"
              disabled={isUpdatingPaymentRun}
              onClick={() => onRemoveTransfer(breakdownByRecipient.id)}
            />
          </PermissionsChecker>
        </Row>

        <Row>
          <AmountCell
            withFlag
            size="medium"
            amount={breakdownByRecipient.amountDue}
            currencyCode={breakdownByRecipient.currency}
          />
          <Row gap={theme.spacing.xs}>
            <Button variant="link" onClick={() => setShowInvoicesPopup(true)}>
              See invoices
            </Button>
          </Row>
        </Row>
      </MobileInvoiceCard>

      {showInvoicesPopup && (
        <MobileInvoicesPopup
          recipientName={breakdownByRecipient.recipientName}
          invoices={breakdownByRecipient.invoices}
          isUpdatingPaymentRun={isUpdatingPaymentRun}
          onClose={() => setShowInvoicesPopup(false)}
          onRemoveInvoice={onRemoveInvoice}
        />
      )}
    </>
  );
};

export default MobileRecipientBreakdownTile;
