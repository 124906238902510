import { FC, useEffect, useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Paragraph, Row, StaleInputSelect, TableSearchInput } from 'components';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import { getCurrenciesFromInvoicesAggregationPerFilter } from 'pages/Invoices/utils';
import { useDebounce } from 'hooks';
import { useStoreState } from 'state';

interface IOwnProps {
  currency: string;
  setCurrency: (value: string) => void;
  filter: string;
  setFilter: (value: string) => void;
  search: string;
  setSearch: (value: string) => void;
}

const Controls: FC<IOwnProps> = ({
  currency,
  filter,
  search,
  setCurrency,
  setFilter,
  setSearch,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { invoicesAggregations } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const [searchInputValue, setSearchInputValue] = useState(search || '');
  const debouncedSearchValue = useDebounce(searchInputValue, 500);

  useEffect(() => {
    setSearch(debouncedSearchValue);
  }, [debouncedSearchValue, setSearch]);

  const currenciesSelectData = useMemo(() => {
    const detectedCurrencies = getCurrenciesFromInvoicesAggregationPerFilter(
      invoicesAggregations,
      'outstanding'
    );
    const sortedDetectedCurrencies = detectedCurrencies.sort((a, b) =>
      a.localeCompare(b)
    );

    return [
      {
        id: 'all',
        name: 'All CCY',
        value: 'all',
      },
      ...sortedDetectedCurrencies.map((currencyCode) => ({
        id: currencyCode,
        name: currencyCode,
        value: currencyCode,
        icon: currencyByCode(currencyCode)?.countryCode,
      })),
    ];
  }, [currencyByCode, invoicesAggregations]);

  return (
    <Row alignSelf="stretch">
      <Row justifyContent="flex-start" gap={theme.spacing.xl} flex={1}>
        <StaleInputSelect
          selected={currency}
          data={currenciesSelectData}
          onSelect={(item) => setCurrency(item.value)}
          id="invoices-currency"
          inputHeight="32px"
          style={{ minWidth: '132px' }}
        />

        <TableSearchInput
          placeholder="Search"
          value={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
        />

        <Row gap={theme.spacing.xs} flex={1} justifyContent="flex-start">
          <FilterButton
            onClick={() => setFilter('nextMonth')}
            active={filter === 'nextMonth'}
          >
            <Row gap={theme.spacing.xs}>
              <Paragraph>Next month</Paragraph>
            </Row>
          </FilterButton>

          <FilterButton
            onClick={() => setFilter('next3Month')}
            active={filter === 'next3Month'}
          >
            <Row gap={theme.spacing.xs}>
              <Paragraph>Next 3 months</Paragraph>
            </Row>
          </FilterButton>
        </Row>
      </Row>
    </Row>
  );
};

export default Controls;
