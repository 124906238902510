import styled, { css } from 'styled-components';
import { ROW_BOTTOM_BORDER_HEIGHT } from './consts';
import { Row } from '../Row/Row';

export const SortIcon = styled.svg<{ active: boolean }>(
  ({ theme, active }) => css`
    width: 16px;
    height: 16px;
    fill: ${active ? theme.color.emeraldDark : theme.color.greyDark};
    vertical-align: middle;
  `
);

export const TableWrapper = styled.div<{
  isVirtualized: boolean;
}>`
  flex: 1;
  border-spacing: 0;
  overflow-x: ${({ isVirtualized }) => (isVirtualized ? 'overlay' : 'unset')};
  display: flex;
  flex-direction: column;
`;

export const TableHead = styled.div`
  display: flex;
`;

export const TableDataCell = styled.div<{
  isExpanderCell?: boolean;
  isSelectableCell?: boolean;
}>(
  ({ theme, isExpanderCell = false, isSelectableCell = false }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    background-color: ${theme.color.white};
    white-space: nowrap;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }

    ${isExpanderCell &&
    css`
      padding-right: 0px;
    `}

    ${isSelectableCell &&
    css`
      padding-left: 24px;
    `}
  `
);

export const TableBody = styled.div<{
  tableHeadHeight?: number;
  isVirtualized?: boolean;
}>`
  position: relative;
  height: ${({ tableHeadHeight, isVirtualized }) =>
    isVirtualized ? `calc(100% - ${tableHeadHeight}px)` : 'auto'};
`;

export const TableRow = styled.div<{
  disabled?: boolean;
  isClickable?: boolean;
  height?: number;
  minRowHeight?: number;
}>(
  ({
    theme,
    disabled = false,
    isClickable = false,
    height,
    minRowHeight,
  }) => css`
    display: flex;
    flex: 1;
    align-items: stretch;
    justify-content: flex-start;
    border: ${`${ROW_BOTTOM_BORDER_HEIGHT}px solid ${theme.color.greyLight_2}`};
    border-top: 0;
    height: ${height && !minRowHeight ? `${height}px` : 'unset'};
    min-height: ${minRowHeight ? `${minRowHeight}px` : 'unset'};
    max-height: ${height ? `${height}px` : 'unset'};

    ${TableDataCell} {
      ${isClickable &&
      `
        cursor: pointer;
      `}
    }

    ${disabled &&
    css`
       {
        ${TableDataCell} {
          background-color: ${theme.color.greyLight_4};

          > :not(.dropdown-menu):not(.dropdown-menu-button):not(.delete):not(.tooltip) {
            opacity: 0.5;
          }
        }
      }
    `}
  `
);

export const TableHeaderCell = styled.div<{
  isSelectableCell?: boolean;
}>(
  ({ theme, isSelectableCell }) => css`
    padding: 12px;
    vertical-align: middle;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: ${theme.color.greyDark};
    text-align: left;
    white-space: nowrap;
    background-color: ${theme.color.greyLight_1};
    display: flex;
    align-items: center;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }

    ${isSelectableCell &&
    css`
      padding-left: 24px;
    `}
  `
);

export const TableExpandButton = styled.button`
  display: flex;
  margin: 0;

  &:hover {
    svg {
      opacity: 0.5;
    }
  }
`;

export const TableFooter = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: ${theme.spacing.m};
    background: ${theme.color.white};
    border-radius: 0px 0px ${theme.borderRadius.m} ${theme.borderRadius.m};
    margin-top: ${theme.spacing.xxs};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      padding: ${theme.spacing.xl} ${theme.spacing.xxxl};
    }
  `
);

export const InfiniteLoadingIndicatorWrapper = styled(Row)`
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 24px;
  background-color: ${({ theme }) => theme.color.emeraldDark};
`;
