import { useMemo } from 'react';
import { useStoreState } from 'state';
import { ITab } from 'components/shared/Tabs/Tabs';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { TInvoicePageTabs } from 'types/invoices';
import { mapStringToInvoicePageTabs } from 'variables/invoicesTabs';
import { getCountFromInvoicesAggregationPerFilter } from 'utils/invoices';

export interface IUseTabsTab extends ITab<TInvoicePageTabs> {
  count?: number;
  filter?: string;
}

interface IOwnProps {
  fundingsCount: number;
}

const useTabs = ({ fundingsCount }: IOwnProps) => {
  const { invoicesAggregations } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const { isMobile } = useDeviceWidth();

  const tabs = useMemo((): IUseTabsTab[] => {
    const outstandingReceivables = getCountFromInvoicesAggregationPerFilter(
      invoicesAggregations,
      'receivables'
    );

    return [
      {
        id: mapStringToInvoicePageTabs['receivables'],
        title: isMobile
          ? 'Outstanding'
          : `Outstanding (${outstandingReceivables})`,
        count: outstandingReceivables,
        filter: 'receivables',
      },
      {
        id: mapStringToInvoicePageTabs['received'],
        title: isMobile ? 'Received' : `Received (${fundingsCount})`,
        count: fundingsCount,
      },
    ];
  }, [isMobile, invoicesAggregations, fundingsCount]);

  return {
    tabs,
  };
};

export default useTabs;
