import styled from 'styled-components';
import { Col } from 'components';

export const MobileInvoicesFooterWrapper = styled(Col)`
  padding: ${({ theme }) => theme.spacing.m};
  background: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.white};
  gap: ${({ theme }) => theme.spacing.m};
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xxs};

  @media (min-width: ${({ theme }) => theme.breakpoint.smallMin}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
