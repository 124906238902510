import styled from 'styled-components';
import { Col } from 'components';

export const ConfirmationStepFooterWrapper = styled(Col)`
  background-color: ${({ theme }) => theme.color.white};
  gap: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.xxs};
  padding: ${({ theme }) => theme.spacing.m};
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
  }
`;

export const ConfirmationStepCurrencyTotalsWrapper = styled(Col)`
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.m};

  @media (min-width: ${({ theme }) => theme.breakpoint.smallMin}px) {
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing.xs};
  }
`;

export const ConfirmationStepButtonsWrapper = styled(Col)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.m};

  @media (min-width: ${({ theme }) => theme.breakpoint.smallMin}px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;
