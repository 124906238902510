import { useQuery } from 'hooks';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { FC, Fragment, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import Icon from '../Icon/Icon';
import ProfileNav from '../ProfileNav/ProfileNav';
import { Row } from '../Row/Row';
import { Paragraph, Title } from '../Typography/Typography';
import { useStoreState } from 'state';
import { DATE_FORMAT } from 'variables';

interface IBreadCrumb {
  id: number;
  link: string;
  label: string;
}

interface PageHeaderProps {
  title?: string;
  withBreadCrumbs?: boolean;
  withProfileNav?: boolean;
  showFreeTrialEndingNotification?: boolean;
}

const pathsWithCurrencies = [
  '/app/invoices/risks',
  '/app/invoices/prebookings',
];

const generateBreadcrumbArray = (
  accumulator: IBreadCrumb[],
  path: string,
  currency?: string
) => {
  const pathTitle = path.split('-').join(' ');
  const pathTitleToUse = pathTitle[0].toUpperCase() + pathTitle.substring(1);
  const labelToUse = currency
    ? `${currency} ${pathTitleToUse}`
    : pathTitleToUse;

  return [
    ...accumulator,
    {
      id: accumulator.length,
      link: `/app/${path}`,
      label: labelToUse,
    },
  ];
};

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  withBreadCrumbs,
  withProfileNav,
  showFreeTrialEndingNotification,
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { isMobile } = useDeviceWidth();
  const URLQuery = useQuery();
  const currencyFromQuery = URLQuery.get('currency');
  const {
    isFxManagementPackageFreeTrialEnabled,
    isAutomationPackageFreeTrialEnabled,
    userEntity,
  } = useStoreState((state) => state.UserState);

  const breadCrumbs = useMemo(() => {
    if (!withBreadCrumbs) {
      return null;
    }

    const paths = pathname.split('/');
    return paths
      .slice(2, paths.length)
      .reduce<IBreadCrumb[]>((accumulator, path) => {
        if (
          !!accumulator.length &&
          currencyFromQuery &&
          pathsWithCurrencies.includes(pathname)
        ) {
          return generateBreadcrumbArray(accumulator, path, currencyFromQuery);
        }

        return generateBreadcrumbArray(accumulator, path);
      }, []);
  }, [pathname, withBreadCrumbs, currencyFromQuery]);

  const showFreeTrial =
    showFreeTrialEndingNotification &&
    (isFxManagementPackageFreeTrialEnabled ||
      isAutomationPackageFreeTrialEnabled);

  return (
    <>
      {!isMobile && title && (
        <Row gap={theme.spacing.m} justifyContent="flex-start">
          <Title variant="h4">{title}</Title>
          {showFreeTrial && (
            <Paragraph color="red" variant="bold">
              Your free trial ends on{' '}
              {dayjs(
                isFxManagementPackageFreeTrialEnabled
                  ? userEntity.packages?.fxManagement.freeTrialUntil
                  : userEntity.packages?.automation.freeTrialUntil
              ).format(DATE_FORMAT)}
            </Paragraph>
          )}
        </Row>
      )}
      {!isMobile && withBreadCrumbs && !!breadCrumbs && (
        <Row gap={theme.spacing.xs} justifyContent="flex-start">
          {breadCrumbs.map(({ id, link, label }, index) => {
            const isLast = index === breadCrumbs.length - 1;
            return (
              <Fragment key={id}>
                {index > 0 && (
                  <Icon icon="arrow-right" width="16px" fill="grey" />
                )}
                {isLast ? (
                  <Title variant="h4" color="dark">
                    {label}
                  </Title>
                ) : (
                  <Link to={link}>
                    <Title variant="h4" color="grey">
                      {label}
                    </Title>
                  </Link>
                )}
              </Fragment>
            );
          })}
        </Row>
      )}
      {withProfileNav && <ProfileNav />}
    </>
  );
};
