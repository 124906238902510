import styled from 'styled-components';
import { Row } from 'components';

export const PaymentRunFooterTotalsWrapper = styled(Row)`
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.smallMin}px) {
    justify-content: flex-end;
  }
`;
