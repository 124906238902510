import { useState } from 'react';
import { IRadioButtonProps } from '../RadioButton/RadioButton';

export interface IFundingAccountCredentialsActions {
  isConfirmed: boolean;
  setIsConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  activeRadioButton?: IRadioButtonProps['value'];
  setActiveRadioButton: (value: IRadioButtonProps['value']) => void;
  isSingleFundingMethod: boolean;
  setIsSingleFundingMethod: React.Dispatch<React.SetStateAction<boolean>>;
  isFundingManually: boolean;
}

const useFundingAccountCredentialsActions = (): IFundingAccountCredentialsActions => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isSingleFundingMethod, setIsSingleFundingMethod] = useState(false);
  const [activeRadioButton, setActiveRadioButton] = useState<
    IRadioButtonProps['value']
  >();

  return {
    isConfirmed,
    setIsConfirmed,
    activeRadioButton,
    setActiveRadioButton,
    isSingleFundingMethod,
    setIsSingleFundingMethod,
    isFundingManually: isConfirmed && activeRadioButton === 'manualTransfer',
  };
};

export default useFundingAccountCredentialsActions;
