import { Col, Table } from 'components';
import useBalance from 'hooks/useBalance';
import { FC, useMemo } from 'react';
import { useStoreState } from 'state';
import {
  IPaymentRunItemSummary,
  IPaymentRunCurrencyTotal,
} from 'types/paymentRuns';
import { generateConfirmationTableColumns } from '../../tableColumnsGenerator';
import ConfirmationStepFooter from './components/ConfirmationStepFooter/ConfirmationStepFooter';
import MobilePaymentRunItemSummaryList from '../AuthoriseStep/components/MobilePaymentRunItemSummaryList/MobilePaymentRunItemSummaryList';
import useDeviceWidth from 'hooks/useDeviceWidth';

interface OwnProps {
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  data: IPaymentRunItemSummary[];
  paymentDate: string;
}

const ConfirmationStep: FC<OwnProps> = ({
  paymentRunTotals,
  data,
  paymentDate,
}) => {
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const { isMobile } = useDeviceWidth();

  const isSingleTransferPaymentRun =
    data.length === 1 && data[0].breakdownByRecipient.length === 1;
  const transferToUse = data[0].breakdownByRecipient[0].transferInput;

  const { isLoading, isEnoughBalance, onPayWithBalance } = useBalance({
    sellCurrency: transferToUse?.sellCurrency,
    sellAmount: transferToUse?.payAmount,
  });

  const canBePaidWithBalance =
    isEnoughBalance && isSingleTransferPaymentRun && transferToUse;

  const tableColumns = useMemo(
    () =>
      generateConfirmationTableColumns({
        currencyByCode,
        entityDefaultCurrencyCode: entityCurrencyCode,
      }),
    [currencyByCode, entityCurrencyCode]
  );

  const footerProps = {
    canBePaidWithBalance: !!canBePaidWithBalance,
    isEnoughBalance,
    isLoading,
    onPayWithBalance,
    transferToUse,
    paymentDate,
    paymentRunTotals,
  };

  return (
    <Col>
      {!isMobile && (
        <Table<IPaymentRunItemSummary>
          data={data}
          columns={tableColumns}
          defaultRowHeight={56}
          renderFooterContent={<ConfirmationStepFooter {...footerProps} />}
          sortable
        />
      )}

      {isMobile && (
        <MobilePaymentRunItemSummaryList
          paymentDate={paymentDate}
          data={data}
          renderFooterContent={<ConfirmationStepFooter {...footerProps} />}
        />
      )}
    </Col>
  );
};

export default ConfirmationStep;
