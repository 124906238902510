import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Col } from 'components/shared/Col/Col';
import MobileRecipientBreakdownTile from './components/MobileRecipientBreakdownTile/MobileRecipientBreakdownTile';
import { IPaymentRunBreakdownByRecipient } from 'types/paymentRuns';

interface IOwnProps {
  data: IPaymentRunBreakdownByRecipient[];
  renderFooterContent: React.ReactNode;
  hasPaymentRun?: boolean;
  isUpdatingPaymentRun?: boolean;
  onRemoveInvoice: (paymentRunInvoiceId: string) => Promise<void>;
  onRemoveTransfer: (paymentRunTransferId: string) => Promise<void>;
}

const MobilePaymentRunBreakdownByRecipientList: FC<IOwnProps> = ({
  data,
  hasPaymentRun,
  isUpdatingPaymentRun,
  renderFooterContent,
  onRemoveInvoice,
  onRemoveTransfer,
}) => {
  const theme = useTheme();

  return (
    <>
      <Col mt mtValue={theme.spacing.xxs} gap={theme.spacing.xxs} flex={1}>
        {data.map((breakdownByRecipient) => (
          <MobileRecipientBreakdownTile
            key={breakdownByRecipient.id}
            breakdownByRecipient={breakdownByRecipient}
            hasPaymentRun={hasPaymentRun}
            isUpdatingPaymentRun={isUpdatingPaymentRun}
            onRemoveInvoice={onRemoveInvoice}
            onRemoveTransfer={onRemoveTransfer}
          />
        ))}
      </Col>

      {renderFooterContent}
    </>
  );
};

export default MobilePaymentRunBreakdownByRecipientList;
