import { Col, Paragraph, TextHint } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

interface IOwnProps {
  paymentFeesAmount: number;
  paymentCurrency: string;
  withTitle?: boolean;
}

const PaymentFeesCell: FC<IOwnProps> = ({
  paymentFeesAmount,
  paymentCurrency,
  withTitle,
}) => {
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const currency = currencyByCode(paymentCurrency);

  return (
    <Col>
      {withTitle && <Paragraph variant="bold">Payment Fees:</Paragraph>}
      <Paragraph variant="bold">
        {parseIntoCurrencyStringWithSymbol(
          paymentFeesAmount,
          currency?.symbol,
          0
        )}
      </Paragraph>
      <TextHint>SWIFT fees</TextHint>
    </Col>
  );
};

export default PaymentFeesCell;
