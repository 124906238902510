import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Col } from 'components/shared/Col/Col';
import MobilePaymentRunItemSummaryTile from './components/MobilePaymentRunItemSummaryTile/MobilePaymentRunItemSummaryTile';
import { IPaymentRunItemSummary } from 'types/paymentRuns';

interface IOwnProps {
  data: IPaymentRunItemSummary[];
  onEdit?: (
    recordId: string,
    updatedData: IPaymentRunItemSummary
  ) => Promise<void>;
  isLoadingBuyFxAll?: boolean;
  paymentDate: string;
  renderFooterContent: React.ReactNode;
}

const MobilePaymentRunItemSummaryList: FC<IOwnProps> = ({
  data,
  onEdit,
  paymentDate,
  isLoadingBuyFxAll,
  renderFooterContent,
}) => {
  const theme = useTheme();

  return (
    <Col>
      <Col mt mtValue={theme.spacing.xxs} gap={theme.spacing.xxs} flex={1}>
        {data.map((paymentRunItemSummary) => (
          <MobilePaymentRunItemSummaryTile
            key={paymentRunItemSummary.id}
            paymentRunItemSummary={paymentRunItemSummary}
            onEdit={onEdit}
            isLoadingBuyFxAll={isLoadingBuyFxAll}
            paymentDate={paymentDate}
          />
        ))}
      </Col>

      {renderFooterContent}
    </Col>
  );
};

export default MobilePaymentRunItemSummaryList;
