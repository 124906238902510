import { FC } from 'react';
import ChartBalancesByAccount from './components/ChartBalancesByAccount/ChartBalancesByAccount';
import { CashNeedsCard } from '../CashNeedsCard/CashNeedsCard.styles';
import { useTheme } from 'styled-components';
import { Title } from 'components/shared/Typography/Typography';
import Loader from 'components/shared/Loader/Loader';
import { ICashNeedsBffResponse } from 'types';

interface IOwnProps {
  data: ICashNeedsBffResponse['balancesByAccountBetweenDates'];
  isLoading: boolean;
}

const BalancesByAccount: FC<IOwnProps> = ({ data, isLoading }) => {
  const theme = useTheme();

  return (
    <CashNeedsCard flex={1} alignSelf="stretch" padding={theme.spacing.m}>
      <Title variant="h5">Balances by account</Title>
      {isLoading && <Loader />}
      {!isLoading && (
        <ChartBalancesByAccount data={data.datasets} labels={data.labels} />
      )}
    </CashNeedsCard>
  );
};

export default BalancesByAccount;
