import { BULK_PAYMENT_STATUS } from './bulkPayments';
import { CONTRACT_STATUS } from './contracts';
import { TRANSFER_STATUS } from './transfers';

interface ISystemFields {
  id: string;
  _created: string;
  _createdBy: string;
  _updated?: string;
  _updatedBy?: string;
  _version: number;
  _owner: string;
}

enum CONVERSION_STATUS {
  awaitingPayment = 'awaiting_payment',
  /** Client has initiated funding, e.g. via Open Banking, but we've not received the funds */
  fundingInitiated = 'funding_initiated',
  funded = 'funded',
  processing = 'processing',
  completed = 'completed',
  cancelled = 'cancelled',
}

type FUNDING_STATUS = 'completed';

interface ITransactionInput extends Omit<ISystemFields, 'id'> {
  /** ISO date string */
  transactedOn: string;
  /** ISO date string */
  settledOn?: string;
  type: 'fxExchange' | 'transferIn' | 'transferOut' | 'fee' | 'rateContract';
  counterPartyName: string;
  amount: number;
  /** amount of an exchange when transaction has FX */
  fxAmount?: number;
  reference?: string;
  mainCurrency: string;
  /** we only have that when transaction has FX */
  fxCurrency?: string;
  direction: 'in' | 'out';
  sourceRecordId: string;
  sourceRecordType:
    | 'transfer'
    | 'conversion'
    | 'rateContract'
    | 'ledgerRecord'
    | 'funding';
  status:
    | CONVERSION_STATUS
    | TRANSFER_STATUS
    | CONTRACT_STATUS
    | BULK_PAYMENT_STATUS
    | FUNDING_STATUS;
}

export interface ITransaction extends ITransactionInput {
  id: string;
}
