import { Col, Paragraph, Row } from 'components';
import Button from 'components/shared/Button/Button';
import dayjs from 'dayjs';
import { FC } from 'react';
import { getDashboardPageLink, getInvoicesPageLink } from 'utils/links';
import { DATE_FORMAT } from 'variables';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';
import { PayWithBalanceParams } from 'services/balances';
import { ITransferInput } from 'types';
import { useHistory } from 'react-router';
import { IPaymentRunCurrencyTotal } from 'types/paymentRuns';
import {
  ConfirmationStepButtonsWrapper,
  ConfirmationStepCurrencyTotalsWrapper,
  ConfirmationStepFooterWrapper,
} from './ConfirmationStepFooter.styles';

interface IOwnProps {
  canBePaidWithBalance: boolean;
  isLoading: boolean;
  isEnoughBalance: boolean;
  paymentDate: string;
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  onPayWithBalance: ({
    payload,
    onSuccess,
  }: {
    payload: Omit<PayWithBalanceParams, 'entityId'>;
    onSuccess?: (() => void) | undefined;
  }) => Promise<void>;
  transferToUse?: Omit<ITransferInput, 'rateType'> & {
    rateType: 'forwardRate' | 'noRate';
  };
}

const ConfirmationStepFooter: FC<IOwnProps> = ({
  canBePaidWithBalance,
  isEnoughBalance,
  isLoading,
  onPayWithBalance,
  transferToUse,
  paymentDate,
  paymentRunTotals,
}) => {
  const history = useHistory();

  return (
    <ConfirmationStepFooterWrapper>
      <Col flex={2.5}>
        <Paragraph mb>
          Your payment run has been booked. Please follow payment instructions
          provided and ensure sufficient funds are on your HedgeFlows account
          before 1pm on the Scheduled date.
        </Paragraph>
        <ConfirmationStepButtonsWrapper>
          {canBePaidWithBalance && transferToUse && (
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() =>
                onPayWithBalance({
                  payload: {
                    currency: transferToUse.sellCurrency,
                    amount: transferToUse.payAmount,
                  },
                  onSuccess: () => history.push(getDashboardPageLink()),
                })
              }
            >
              Use my balance
            </Button>
          )}
          <Button
            disabled={isLoading}
            variant={canBePaidWithBalance ? 'secondary' : 'primary'}
            onClick={() =>
              history.replace(
                getInvoicesPageLink({
                  currency: 'all',
                })
              )
            }
          >
            Go to invoices
          </Button>
          <Button
            disabled={isLoading}
            variant={isEnoughBalance ? 'link' : 'secondary'}
            onClick={() => history.replace(getDashboardPageLink())}
          >
            Go to dashboard
          </Button>
        </ConfirmationStepButtonsWrapper>
      </Col>

      <ConfirmationStepCurrencyTotalsWrapper>
        <Row alignItems="flex-start" alignSelf="stretch">
          <Paragraph variant="bold">Payments scheduled for:</Paragraph>
          <Paragraph variant="bold">
            {dayjs(paymentDate).format(DATE_FORMAT)}
          </Paragraph>
        </Row>
        <InvoicesCurrencyTotals
          title="Funds required:"
          currencyTotals={paymentRunTotals}
        />
      </ConfirmationStepCurrencyTotalsWrapper>
    </ConfirmationStepFooterWrapper>
  );
};

export default ConfirmationStepFooter;
