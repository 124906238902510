import dayjs from 'dayjs';
import { TLogsPaths, TSettingsPaths } from 'routes/IntegrationsRouteUtil';
import { IInvoice, IInvoiceFromSearch, TAccountingSystem } from 'types';
import { getInvoiceRemainingAmount } from './invoices';
import {
  TRiskPagePopupType,
  TRisksMonthlyBreakdownContentType,
} from 'pages/Risks/components/MonthlyBreakdown/types';

interface PrebookLinkParams {
  step?: string;
  invoiceId?: string;
  predefinedBuyCurrency?: string;
  predefinedSellCurrency?: string;
  predefinedBuyAmount?: string;
  predefinedSellAmount?: string;
  predefinedDate?: string;
}

interface CurrencyExchangeLinkParams {
  step?: string;
  invoiceId?: string;
  predefinedBuyCurrency?: string;
  predefinedSellCurrency?: string;
  predefinedBuyAmount?: string;
  predefinedSellAmount?: string;
  predefinedDate?: string;
  predefinedRateContractId?: string;
}

interface TransferLinkParams {
  predefinedRateContractId?: string;
  predefinedBuyCurrency?: string;
  predefinedSellCurrency?: string;
  predefinedBuyAmount?: string;
  invoiceId?: string;
  step?: string;
  bulkId?: string;
}

interface SimpleTransferLinkParams {
  invoiceId?: string;
  predefinedCurrency?: string;
  predefinedAmount?: string;
}

interface PrebooksOverviewLinkParams {
  currency?: string;
  contractId?: string;
  hedgeId?: string;
  cancelContractId?: string;
  deleteContractId?: string;
}

interface PaymentRunLinkParams {
  step: string;
}
interface InvoicesLinkParams {
  currency: string;
  tab?: string;
  filter?: string;
  search?: string;
  contractId?: string;
  hedgeId?: string;
  cancelContractId?: string;
  deleteContractId?: string;
  noSettingsRedirect?: string;
}

interface InvoicesToCollectLinkParams {
  currency: string;
  tab?: string;
  filter?: string;
}

interface ReduceRecommendationsRiskLinkParams {
  currency?: string;
}

interface TransactionsLinkParams {
  transferId?: string;
  contractId?: string;
  bulkPaymentId?: string;
}

interface IntegrationSettingsLinkParams {
  step?: string;
  firstIntegration?: boolean;
}

interface BulkUploadLinkParams {
  combineSameContacts?: boolean;
  invoicesIds?: string;
}

interface SettingsLinkParams {
  anchor?: string;
  tab?: string;
}

interface DashboardLinkParams {
  contractId?: string;
}

interface RisksLinkParams {
  currency: string;
  chartContentType?: TRisksMonthlyBreakdownContentType;
  popupType?: TRiskPagePopupType;
}

interface RiskSettingsLinkParams {
  step?: string;
}

interface OpenBankingLinkParams {
  accountId?: string;
}

interface ManualUploadLinkParams {
  tab?: string;
  dataType?: string;
  /** used for manual hedge upload */
  buyCurrency?: string;
  /** used for manual invoice upload */
  currency?: string;
  /** used for manual invoice edit */
  invoiceToEditId?: string;
  /** used for manual external hedge edit */
  externalHedgeToEditId?: string;
}

interface InvoiceDetailsLinkParams {
  invoiceId: string;
}

interface PaymentInitiationLinkParams {
  bulkPaymentId: string;
}

interface LinkParams extends TSettingLinkParams, TLogPathLinkParams {
  '/app/prebook': PrebookLinkParams;
  '/app/currency-exchange': CurrencyExchangeLinkParams;
  '/app/transfer': TransferLinkParams;
  '/app/simple-transfer': SimpleTransferLinkParams;
  '/app/invoices': InvoicesLinkParams;
  '/app/receivables': InvoicesToCollectLinkParams;
  '/app/invoices/prebookings': PrebooksOverviewLinkParams;
  '/app/invoices/payment-run': PaymentRunLinkParams;
  '/app/risks/reduce-recommendations': ReduceRecommendationsRiskLinkParams;
  '/app/transactions': TransactionsLinkParams;
  '/app/transactions/download': {};
  '/app/settings': SettingsLinkParams;
  '/app/dashboard': DashboardLinkParams;
  '/app/settings/xero': IntegrationSettingsLinkParams;
  '/app/bulk-upload': BulkUploadLinkParams;
  '/app/logs/xero': {};
  '/app/new-client/xero': {};
  '/app/my-clients': {};
  '/app/switch-clients': {};
  '/login': {};
  '/app/company-registration': {};
  '/app/payment-runs': {};
  '/app/risks': RisksLinkParams;
  '/app/reports': {};
  '/app/risk-settings': RiskSettingsLinkParams;
  '/app/open-banking-settings': OpenBankingLinkParams;
  '/app/invoices/manual-upload': ManualUploadLinkParams;
  '/app/contacts': {};
  '/app/invoice-details': InvoiceDetailsLinkParams;
  '/app/payment-initiation': PaymentInitiationLinkParams;
  '/app/transfers': {};
  '/app/fx-trades': {};
}

type TSettingLinkParams = Record<TSettingsPaths, IntegrationSettingsLinkParams>;
type TLogPathLinkParams = Record<TLogsPaths, {}>;

export const getLink = <K extends keyof LinkParams, V extends LinkParams[K]>(
  baseUrl: K,
  params?: V,
  anchor?: string
) => {
  if (!params) {
    return baseUrl;
  }

  const queryParams = new URLSearchParams();

  Object.keys(params).forEach(
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    (key) => params[key] && queryParams.append(key, params[key])
  );

  return `${baseUrl}?${queryParams.toString()}${anchor ?? ''}`;
};

// page specific link getters
export const getPrebookPageLink = (args?: PrebookLinkParams) =>
  getLink('/app/prebook', args);

export const getTransactionPageLink = (args?: TransactionsLinkParams) =>
  getLink('/app/transactions', args);

export const getTransactionsDownloadPageLink = () =>
  getLink('/app/transactions/download');

export const getPaymentRunsPageLink = () => getLink('/app/payment-runs');

export const getSettingsPageLink = (
  args?: SettingsLinkParams,
  anchor?: string
) => getLink('/app/settings', args, anchor);

export const getIntegrationSettingsPageLink = (
  system: TAccountingSystem,
  args?: IntegrationSettingsLinkParams
) => getLink(`/app/settings/${system}` as TSettingsPaths, args);

export const getIntegrationLogsPageLink = (system: TAccountingSystem) =>
  getLink(`/app/logs/${system}` as TLogsPaths);

export const getInvoicesPageLink = (args: InvoicesLinkParams) =>
  getLink('/app/invoices', args);

export const getInvoicesToCollectPageLink = (
  args: InvoicesToCollectLinkParams
) => getLink('/app/receivables', args);

export const getPrebookingsPageLink = (args?: PrebooksOverviewLinkParams) =>
  getLink('/app/invoices/prebookings', args);

export const getCurrencyExchangePageLink = (args: CurrencyExchangeLinkParams) =>
  getLink('/app/currency-exchange', args);

export const getPaymentRunPageLink = (args?: PaymentRunLinkParams) =>
  getLink('/app/invoices/payment-run', args);

export const getReduceRiskRecommendationsPageLink = (
  args: ReduceRecommendationsRiskLinkParams
) => getLink('/app/risks/reduce-recommendations', args);

export const getDashboardPageLink = (args?: DashboardLinkParams) =>
  getLink('/app/dashboard', args);

export const getTransfersPageLink = (args?: TransferLinkParams) =>
  getLink('/app/transfer', args);

export const getXeroNewClientLink = () => getLink('/app/new-client/xero');
export const getMyClientsLink = () => getLink('/app/my-clients');
export const getSwitchClientsLink = () => getLink('/app/switch-clients');

export const getLoginPageLink = () => getLink('/login');
export const getCompanyRegistrationPageLink = () =>
  getLink('/app/company-registration');
export const getRisksPageLink = (args?: RisksLinkParams) =>
  getLink('/app/risks', args);
export const getReportsPageLink = () => getLink('/app/reports');
export const getContactsPageLink = () => getLink('/app/contacts');

export const getPaymentInitiationPageLink = (
  args: PaymentInitiationLinkParams
) => getLink('/app/payment-initiation', args);

export const getInvoiceTransferLink = (
  invoice: IInvoice | IInvoiceFromSearch
) => {
  if (!invoice.id) {
    return '';
  }
  return getLink('/app/transfer', {
    step: '1',
    invoiceId: invoice.id,
    predefinedBuyCurrency: invoice.currency,
    predefinedBuyAmount: getInvoiceRemainingAmount(invoice).toString(),
    predefinedRateContractId: invoice.contractId,
  });
};

export const getInvoiceSimpleTransferLink = (
  invoice: IInvoice | IInvoiceFromSearch
) => {
  if (!invoice.id) {
    return '';
  }

  return getLink('/app/simple-transfer', {
    invoiceId: invoice.id,
    predefinedCurrency: invoice.currency,
    predefinedAmount: getInvoiceRemainingAmount(invoice).toString(),
  });
};

export const getInvoiceTransferOrSimpleTransferLink = (
  invoice: IInvoice | IInvoiceFromSearch,
  entityCurrencyCode: string
) =>
  entityCurrencyCode === invoice.currency
    ? getInvoiceSimpleTransferLink(invoice)
    : getInvoiceTransferLink(invoice);

export const getInvoicePrebookLink = (
  invoice: IInvoice | IInvoiceFromSearch,
  entityCurrency: string
) => {
  if (invoice.type === 'Receivable') {
    return getLink('/app/prebook', {
      step: '2',
      invoiceId: invoice.id,
      predefinedBuyCurrency: entityCurrency,
      predefinedSellCurrency: invoice.currency,
      predefinedSellAmount: getInvoiceRemainingAmount(invoice).toString(),
      predefinedDate: dayjs(invoice.dueDate).toISOString(),
    });
  }

  return getLink('/app/prebook', {
    step: '2',
    invoiceId: invoice.id,
    predefinedBuyCurrency: invoice.currency,
    predefinedBuyAmount: getInvoiceRemainingAmount(invoice).toString(),
    predefinedDate: dayjs(invoice.dueDate).toISOString(),
  });
};

export const getInvoiceDetailsLink = (args?: InvoiceDetailsLinkParams) =>
  getLink('/app/invoice-details', args);

export const getInvoiceManualUploadLink = (args?: ManualUploadLinkParams) =>
  getLink('/app/invoices/manual-upload', args);

export const getRiskSettingsPageLink = (args?: RiskSettingsLinkParams) =>
  getLink('/app/risk-settings', args);

export const getOpenBankingSettingsPageLink = (args?: OpenBankingLinkParams) =>
  getLink('/app/open-banking-settings', args);

export const getSimpleTransferPageLink = (args?: SimpleTransferLinkParams) =>
  getLink('/app/simple-transfer', args);
