import { Button, Paragraph, Popup, Row, Title } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';
import { IPastPerformanceItem } from 'types';
import { parseIntoCurrencyStringWithSymbol, parseRate } from 'utils';
import { getValueColor } from '../../utils';

interface IOwnProps {
  record: IPastPerformanceItem;
  onClose: () => void;
}

const FxImpactBreakdown: FC<IOwnProps> = ({ record, onClose }) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  return (
    <Popup
      HeaderContent={<Title variant="h2">FX Impact</Title>}
      FooterContent={
        <Row>
          <Button onClick={onClose}>Close</Button>
        </Row>
      }
      onClose={onClose}
    >
      <Paragraph mb>
        We estimate the impact of currency fluctuations between two dates:
      </Paragraph>

      <table>
        <thead>
          <th />
          <th>
            <Paragraph variant="bold" textAlign="end">
              FX rate
            </Paragraph>
          </th>
          <th>
            <Paragraph variant="bold" textAlign="end">
              {entityCurrencyCode} value
            </Paragraph>
          </th>
        </thead>
        <tr>
          <td>
            <Paragraph>Invoice date/prev. month</Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseRate(
                1 /
                  (record.previousPeriodSystemRate ??
                    record.creationDateSystemRate ??
                    1)
              )}
            </Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseIntoCurrencyStringWithSymbol(
                Math.abs(record.creationDateOrPreviousPeriodAmount ?? 0),
                currencyByCode(entityCurrencyCode)?.symbol
              )}
            </Paragraph>
          </td>
        </tr>
        <tr>
          <td>
            <Paragraph>Month end/payment date</Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseRate(1 / record.endOfPeriodSystemRate)}
            </Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseIntoCurrencyStringWithSymbol(
                Math.abs(record.endOfCurrentPeriodAmount),
                currencyByCode(entityCurrencyCode)?.symbol
              )}
            </Paragraph>
          </td>
        </tr>

        <tr>
          <td>
            <Paragraph>FX gain/loss from FX moves</Paragraph>
          </td>
          <td />
          <td>
            <Paragraph
              variant="bold"
              textAlign="end"
              color={getValueColor(record.fxImpact)}
            >
              {parseIntoCurrencyStringWithSymbol(
                record.fxImpact,
                currencyByCode(entityCurrencyCode)?.symbol
              )}{' '}
              ({((record.fxImpactPercentage ?? 0) * 100)?.toPrecision(3)}%)
            </Paragraph>
          </td>
        </tr>
      </table>
    </Popup>
  );
};

export default FxImpactBreakdown;
