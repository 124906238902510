import { IPastPerformanceItem, isPastPerformanceItemTransfer } from 'types';
import { CellProps, Column } from 'react-table';
import { Paragraph } from 'components';
import DateCell from 'components/shared/DateCell/DateCell';
import DirectionCell from 'components/shared/DirectionCell/DirectionCell';
import AmountCell from '../AmountCell/AmountCell';
import RiskContributionCell from '../RiskContributionCell/RiskContributionCell';
import AmountWithPercentageCell from '../AmountWithPercentageCell/AmountWithPercentageCell';
import { useTheme } from 'styled-components';
import CashflowCell from './components/CashflowCell/CashflowCell';
import CounterPartyCell from './components/CounterPartyCell/CounterPartyCell';

const RECORD_TYPE_TO_TITLE = {
  invoice: 'Invoice',
  bankTransfer: 'Bank Transfer',
};

export const generateReportsTableColumns = ({
  entityCurrency,
}: {
  entityCurrency: string;
}): Column<IPastPerformanceItem>[] => {
  return [
    {
      Header: 'Cashflow',
      disableSortBy: true,
      Cell: ({ row }: CellProps<IPastPerformanceItem>) => {
        const item = row.original;

        return <CashflowCell item={item} />;
      },
      width: 80,
      minWidth: 55,
    },
    {
      accessor: 'recordCreationDate',
      Header: 'Issued',
      Cell: ({ value }: CellProps<IPastPerformanceItem>) => (
        <DateCell value={value} />
      ),
      width: 130,
      minWidth: 110,
    },
    {
      accessor: 'recordPaidDate',
      Header: 'Paid',
      Cell: ({ value }: CellProps<IPastPerformanceItem>) => (
        <DateCell value={value} />
      ),
      width: 100,
      minWidth: 100,
    },
    {
      accessor: 'counterParty',
      Header: 'Counterparty',
      Cell: ({ row }: CellProps<IPastPerformanceItem>) => (
        <CounterPartyCell record={row.original} />
      ),
      width: 120,
      minWidth: 100,
    },
    {
      accessor: 'recordType',
      Header: 'Type',
      Cell: ({ row }: CellProps<IPastPerformanceItem>) => (
        <Paragraph>{RECORD_TYPE_TO_TITLE[row.original.recordType]}</Paragraph>
      ),
      width: 120,
      minWidth: 100,
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: (props: CellProps<IPastPerformanceItem>) => {
        const item = props.row.original;

        return <Paragraph>{item.recordStatus}</Paragraph>;
      },
      width: 140,
      minWidth: 120,
    },
    {
      Header: 'Amount Due',
      accessor: 'totalAmount',
      Cell: ({ row }: CellProps<IPastPerformanceItem>) => {
        const item = row.original;
        const isReceivable = item.direction === 'IN';
        return (
          <>
            <DirectionCell withTitle={false} isReceivable={isReceivable} />
            <AmountCell
              value={row.original.totalAmount}
              currencyCode={row.original.currency}
            />
          </>
        );
      },
      width: 120,
      minWidth: 100,
    },
    {
      id: 'amountInEntityCurrency',
      Header: `${entityCurrency} Value`,
      disableSortBy: true,
      Cell: ({ row }: CellProps<IPastPerformanceItem>) => (
        <AmountCell
          value={
            row.original.totalAmount /
            (row.original.payRate ?? row.original.recordRate)
          }
          currencyCode={entityCurrency}
        />
      ),
      width: 120,
      minWidth: 100,
    },
    {
      accessor: 'inceptionPnl',
      Header: 'Inception PnL',
      Cell: ({ value, row }: CellProps<IPastPerformanceItem>) => {
        const theme = useTheme();

        return (
          <AmountWithPercentageCell
            value={value}
            valuePercentage={row.original.inceptionPnlPercentage}
            currencyCode={entityCurrency}
            textColor={theme.color.blue}
            record={row.original}
            breakdownType="inceptionPnl"
          />
        );
      },
      width: 140,
      minWidth: 140,
    },
    {
      accessor: 'fxImpact',
      Header: 'FX Impact',
      Cell: ({ value, row }: CellProps<IPastPerformanceItem>) => (
        <AmountWithPercentageCell
          value={value}
          valuePercentage={row.original.fxImpactPercentage}
          currencyCode={entityCurrency}
          textColor="#039464"
          record={row.original}
          breakdownType="fxImpact"
        />
      ),
      width: 140,
      minWidth: 140,
    },
    {
      accessor: 'fxCost',
      Header: 'FX Cost',
      Cell: ({ value, row }: CellProps<IPastPerformanceItem>) => {
        const theme = useTheme();
        return (
          <AmountWithPercentageCell
            value={value}
            valuePercentage={row.original.fxCostPercentage}
            currencyCode={entityCurrency}
            textColor={theme.color.red}
            record={row.original}
            breakdownType="fxCost"
          />
        );
      },
      width: 140,
      minWidth: 140,
    },
    {
      id: 'riskContribution',
      Header: 'Risk contrib.',
      disableSortBy: true,
      Cell: ({ row }: CellProps<IPastPerformanceItem>) => (
        <RiskContributionCell
          amount={
            // 0 risk contribution for transfer items
            isPastPerformanceItemTransfer(row.original)
              ? 0
              : row.original.totalAmount / (row.original.payRate ?? 1)
          }
          currencyCode={entityCurrency}
          creationDate={row.original.recordCreationDate}
          paidDate={row.original.recordPaidDate ?? ''}
        />
      ),
      width: 100,
      minWidth: 100,
    },
  ];
};
