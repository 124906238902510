import { ChartData } from 'chart.js';
import { useTheme } from 'styled-components';
import { IMonthlyReport } from 'types';
import { POPULAR_CURRENCIES_WITH_COLORS } from 'variables';

export const getDatasets = (
  data: IMonthlyReport[],
  isAllCurrencies: boolean,
  theme: ReturnType<typeof useTheme>
) => {
  let datasets: ChartData<'bar', number[], Date>['datasets'] = [];

  if (isAllCurrencies) {
    const currencies = data[0].currencyBreakdown.map((item) => item.currency);
    datasets = currencies.map((currency) => ({
      type: 'bar',
      label: currency,
      data: data.map(
        (item) =>
          item.currencyBreakdown.find((cb) => cb.currency === currency)
            ?.totalValue ?? 0
      ),
      borderColor: POPULAR_CURRENCIES_WITH_COLORS[currency] ?? theme.color.red,
      backgroundColor:
        POPULAR_CURRENCIES_WITH_COLORS[currency] ?? theme.color.red,
    }));

    datasets.push({
      type: 'bar',
      label: 'FX PnL from ext. system',
      data: data.map((item) => item.pnlFromExternalSystem ?? 0),
      borderColor: theme.color.purple,
      backgroundColor: theme.color.purple,
      stack: 'external',
    });
  } else {
    datasets = [
      {
        type: 'bar',
        label: 'FX Cost',
        data: data.map((item) => item.fxCost),
        borderColor: theme.color.red,
        backgroundColor: theme.color.red,
      },
      {
        type: 'bar',
        label: 'FX Impact',
        data: data.map((item) => item.fxImpact),
        borderColor: '#039464',
        backgroundColor: '#039464',
      },
      {
        type: 'bar',
        label: 'Inception PnL',
        data: data.map((item) => item.inceptionPnl),
        borderColor: theme.color.blue,
        backgroundColor: theme.color.blue,
      },
    ];
  }

  return datasets;
};
