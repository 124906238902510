import { useEffect, useState, useCallback, useMemo } from 'react';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import { useDebounce } from 'hooks';
import { ITableFilter } from 'components/shared/Filters/types';
import { TInvoicesAggregations } from 'types';
import { getCurrenciesFromInvoicesAggregationPerFilter } from 'pages/Invoices/utils';
import { detectCurrenciesFromFundings } from 'utils/finding';
import { generateDateUrlValuePayload } from 'utils/invoices';
import { errorHandler } from 'utils/errors';
import { getFundingsToCollectCurrencies } from 'services/funding';

const useControls = () => {
  const { setUrlValue, tab, currency, filter, search } = useUrlValues(
    'tab',
    'currency',
    'filter',
    'search'
  );
  const { currencies, currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { invoicesAggregations } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const [searchInputValue, setSearchInputValue] = useState(search || '');
  const debouncedSetSearchValue = useDebounce(searchInputValue, 500);

  const [fundingCurrencies, setFundingCurrencies] = useState<string[]>([]);
  const [isLoadingFundingCurrencies, setIsLoadingFundingCurrencies] = useState(
    true
  );

  useEffect(() => {
    getFundingsToCollectCurrencies()
      .then((result) => {
        if (result.data.data) {
          setFundingCurrencies(result.data.data);
        }
        setIsLoadingFundingCurrencies(false);
      })
      .catch(errorHandler);
  }, []);

  const currenciesSelectData = useMemo(() => {
    const invoicesDetectedCurrencies = getCurrenciesFromInvoicesAggregationPerFilter(
      invoicesAggregations,
      (tab as keyof TInvoicesAggregations['perFilter']) || 'receivables'
    );

    const invoicesCurrencies = invoicesDetectedCurrencies.sort((a, b) =>
      a.localeCompare(b)
    );

    const fundingCurrenciesData = detectCurrenciesFromFundings({
      fundingCurrencies,
      currencies,
    });

    return [
      {
        id: 'all',
        name: 'All CCY',
        value: 'all',
      },
      ...(tab === 'received'
        ? fundingCurrenciesData.map((currencyCode) => ({
            id: currencyCode,
            name: currencyCode,
            value: currencyCode,
            icon: currencyByCode(currencyCode)?.countryCode,
          }))
        : invoicesCurrencies.map((currencyCode) => ({
            id: currencyCode,
            name: currencyCode,
            value: currencyCode,
            icon: currencyByCode(currencyCode)?.countryCode,
          }))),
    ];
  }, [
    currencyByCode,
    invoicesAggregations,
    tab,
    currencies,
    fundingCurrencies,
  ]);

  const onFilterChange = useCallback(
    (filters: ITableFilter[]) => {
      const urlValuePayload = generateDateUrlValuePayload(filters);
      setUrlValue(urlValuePayload);
    },
    [setUrlValue]
  );

  // TODO: Remove after refactor of useUrlValues (add initial values for props to avoid useEffect)
  useEffect(() => {
    setUrlValue({
      search: debouncedSetSearchValue,
    });
  }, [debouncedSetSearchValue, setUrlValue]);

  useEffect(() => {
    if (!tab) {
      if (!filter) {
        setUrlValue({ tab: 'receivables', filter: 'receivables' });
      } else {
        setUrlValue({ tab: 'receivables' });
      }
    }

    if (tab === 'receivables' && !filter) {
      setUrlValue({ filter: 'receivables' });
    }
  }, [tab, setUrlValue, filter]);

  return {
    currenciesSelectData,
    searchInputValue,
    setSearchInputValue,
    onFilterChange,
    isLoadingFundingCurrencies,
    urlValues: { setUrlValue, tab, currency, filter, search },
  };
};

export default useControls;
