import { FC } from 'react';
import { useTheme } from 'styled-components';
import {
  Paragraph,
  ParagraphWithEllipsis,
  PermissionsChecker,
  Row,
  StaleInfo,
} from 'components';

import { IPaymentRunBreakdownByRecipientInvoice } from 'types/paymentRuns';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import { MobileInvoiceCard } from 'components/shared/MobileInvoiceCard/MobileInvoiceCard.styles';

interface IOwnProps {
  invoice: IPaymentRunBreakdownByRecipientInvoice;
  onRemoveInvoice: (paymentRunInvoiceId: string) => Promise<void>;
  isUpdatingPaymentRun?: boolean;
}

const MobileInvoicesTile: FC<IOwnProps> = ({
  invoice,
  isUpdatingPaymentRun,
  onRemoveInvoice,
}) => {
  const theme = useTheme();

  return (
    <>
      <MobileInvoiceCard padding={theme.spacing.m} gap={theme.spacing.m}>
        <Row>
          <Row flex={1}>
            <Row>
              <ParagraphWithEllipsis
                maxWidth="150px"
                mr
                mrValue={theme.spacing.xs}
              >
                {invoice.reference}
              </ParagraphWithEllipsis>
              {!invoice.valid && (
                <StaleInfo strategy="fixed" mode="click" placement="top" portal>
                  <Paragraph color="white">{invoice.error}</Paragraph>
                </StaleInfo>
              )}
            </Row>

            <Paragraph>{invoice.details}</Paragraph>
          </Row>
        </Row>

        <Row>
          <AmountCell
            withFlag
            size="medium"
            amount={invoice.amountDue}
            currencyCode={invoice.currency}
          />

          <Row>
            <PermissionsChecker action="update" resource="payment_runs">
              <ActionButton
                disabled={isUpdatingPaymentRun}
                onClick={() => onRemoveInvoice(invoice.id)}
              >
                Remove
              </ActionButton>
            </PermissionsChecker>
          </Row>
        </Row>
      </MobileInvoiceCard>
    </>
  );
};

export default MobileInvoicesTile;
