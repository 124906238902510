import { IIntegrationSettingsAccount, TAccountingSystem } from 'types';

type IAvailableIntegrationIds =
  | 'csv'
  | 'xero'
  | 'dynamics'
  | 'netsuite'
  | 'quickbooks';

type IAvailableIntegrationNames =
  | 'Xero'
  | 'QuickBooks Sandbox'
  | 'QuickBooks'
  | 'Dynamics 365 Business Central'
  | 'CSV'
  | 'Oracle NetSuite';

const nonXeroPlatformNames = [
  'QuickBooks Online Sandbox',
  'QuickBooks Online',
  'Dynamics 365 Business Central',
  'Oracle NetSuite',
] as const;

export type TNonXeroPlatformNames = typeof nonXeroPlatformNames[number];

export type TPlatformNames = TNonXeroPlatformNames | 'Xero';

export interface IAvailableIntegration {
  id: IAvailableIntegrationIds;
  name: IAvailableIntegrationNames;
  img?: string;
  type: INTEGRATION_TYPE;
  platformNames?: TNonXeroPlatformNames[];
  available: boolean;
  isBeta?: boolean;
  system?: TAccountingSystem;
}

export interface IIntegrationStatusSummary {
  system: TAccountingSystem;
  connected: boolean;
  reauthenticate: boolean;
  platformName: TPlatformNames;
  sourceCompanyName?: string;
}

export interface IIntegrationPermissions {
  global: boolean;
  invoices?: boolean;
  payments?: boolean;
  purchaseOrders?: boolean;
  bills?: boolean;
  accounts?: boolean;
  bankFees?: boolean;
  bankTransfers?: boolean;
  importInvoices: boolean;
  importBills: boolean;
  importPayments: boolean;
  importContactDetails: boolean;
  importBankBalances: boolean;
  importPurchaseAndSalesOrders: boolean;
}

export interface IAccountData {
  id: string;
  value: IIntegrationSettingsAccount | null;
  name: string;
}

export enum INTEGRATION_TYPE {
  accounting = 'accounting',
  business = 'business',
}

export const isNonXeroAccount = (
  account: IIntegrationSettingsAccount
): account is IIntegrationSettingsAccount => account.id !== undefined;

export const isXeroPlatform = (
  platformName: TPlatformNames
): platformName is 'Xero' => platformName === 'Xero';

export const isDynamicsPlatform = (
  platformName: TPlatformNames
): platformName is 'Dynamics 365 Business Central' =>
  platformName === 'Dynamics 365 Business Central';

export const isNonXeroPlatform = (
  platformName: TPlatformNames
): platformName is TNonXeroPlatformNames =>
  nonXeroPlatformNames.includes(platformName as TNonXeroPlatformNames);
