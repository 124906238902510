import React from 'react';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import PermissionsChecker from 'components/PermissionsChecker/PermissionsChecker';
import { IInvoiceFromSearch } from 'types';
import useInvoicePrebookAndTransfer from 'components/shared/InvoicesTable/hooks/useInvoicePrebookAndTransfer';
import { Icon, Row } from 'components';

interface IOwnProps {
  record: IInvoiceFromSearch;
}

const ActionCell: React.FC<IOwnProps> = ({ record }) => {
  const { goToInvoicePrebook } = useInvoicePrebookAndTransfer();

  const handleBookClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    goToInvoicePrebook(record);
  };

  if (record.contractId) {
    return (
      <Row flex={1} justifyContent="center">
        <Icon icon="lock-ico" width="24px" height="24px" />
      </Row>
    );
  }

  return (
    <PermissionsChecker action="create" resource="prebooks">
      <ActionButton onClick={handleBookClick}>Prebook FX</ActionButton>
    </PermissionsChecker>
  );
};

export default ActionCell;
