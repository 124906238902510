import { useState } from 'react';
import { Title, Icon, Paragraph, Col, Row } from 'components';
import { useStoreActions, useStoreState } from 'state';
import {
  IIntegrationSettingsAccount,
  IIntegrationSettingsPermissions,
  MakeOptional,
  IIntegrationSettingsInput,
} from 'types';
import { IAccountData } from 'types/integrations';
import { Notify } from 'utils';
import TitleLoader from '../TitleLoader/TitleLoader';
import { saveIntegrationEngineSettings } from 'services/firebase';
import { useTheme } from 'styled-components';
import { StepAccountsSecondNotification } from 'components/shared/StepAccountsSecondNotification/StepAccountsSecondNotification.styles';
import useIntegrationAccounts from 'hooks/useIntegrationAccounts';
import UnorderedList from '../UnorderedList/UnorderedList';
import Pill from '../Pill/Pill';
import Button from '../Button/Button';
import { IFormBankTransferXeroAccount } from '../StepAccountsFirst/components/StepAccountsForm/StepAccountsForm';
import { ERROR_MESSAGES } from 'variables';
import {
  generateIntegrationSettingsAccountDocIdsToDelete,
  generateIntegrationSettingsBankTransferAccountsToSave,
} from 'utils/integrations';

interface Inputs {
  paymentsAndConversions: boolean;
  invoicesAndBills: boolean;
  withBankFees: boolean;
  withExistingAccount: boolean;
  mainSellCurrencyAccount: IAccountData | null;
  createNewAccount: boolean;
}

interface ISavedValues extends MakeOptional<Inputs> {
  bankFeesAccount?: IAccountData | null;
  formBankTransferAccounts: Record<string, IFormBankTransferXeroAccount> | null;
}

interface OwnProps {
  savedValues?: ISavedValues;
  onContinue: () => void;
}

const StepAccountsSecondXero = ({ savedValues, onContinue }: OwnProps) => {
  const theme = useTheme();
  const { integrationEngineSettings } = useStoreState(
    ({ UserState }) => UserState
  );
  const { entityId } = useStoreState(({ UserState }) => UserState);
  const { getIntegrationEngineSettings } = useStoreActions(
    (state) => state.UserState
  );
  const { isLoadingAccounts, accounts } = useIntegrationAccounts('Xero');
  const [isLoading, setIsLoading] = useState(false);
  const savedSettings = integrationEngineSettings?.settings;

  const onSubmit = async () => {
    if (!entityId) {
      Notify.error(ERROR_MESSAGES.noEntityId);
      return;
    }

    try {
      setIsLoading(true);

      const permissions: Partial<IIntegrationSettingsPermissions> = {
        invoices: savedValues?.invoicesAndBills,
        payments: savedValues?.paymentsAndConversions,
        purchaseOrders: savedValues?.invoicesAndBills,
        bills: savedValues?.invoicesAndBills,
        bankFees: !!savedValues?.bankFeesAccount,
        accounts: savedValues?.paymentsAndConversions,
        bankTransfers: !!savedValues?.formBankTransferAccounts,
      };

      const settingsInput: IIntegrationSettingsInput = {
        permissions,
        accountDocIdsToDelete: [],
      };

      if (savedSettings?.monthsInThePastToQueryFor) {
        settingsInput.settings = {
          monthsInThePastToQueryFor: savedSettings?.monthsInThePastToQueryFor,
          excludedCurrencies: savedSettings?.excludedCurrencies,
        };
      }

      const settingsAccountsForUpdate: IIntegrationSettingsAccount[] = [];

      // Bank Fees Account
      if (savedValues?.bankFeesAccount?.value) {
        const {
          id,
          name,
          code,
          currency,
          category,
        } = savedValues.bankFeesAccount.value;
        const bankFeesAccount: IIntegrationSettingsAccount = {
          id,
          name,
          code,
          currency,
          type: 'bankFeesAccount',
          category,
        };
        settingsAccountsForUpdate.push(bankFeesAccount);
      }

      // Bank Transfer Accounts
      if (savedValues?.formBankTransferAccounts) {
        const bankTransferAccountsToSave = generateIntegrationSettingsBankTransferAccountsToSave(
          Object.values(savedValues.formBankTransferAccounts)
        );

        settingsAccountsForUpdate.push(...bankTransferAccountsToSave);
      }

      if (settingsAccountsForUpdate.length > 0) {
        settingsInput.accounts = settingsAccountsForUpdate;

        settingsInput.accountDocIdsToDelete = generateIntegrationSettingsAccountDocIdsToDelete(
          {
            savedAccounts: integrationEngineSettings.accounts,
            updatedAccounts: settingsAccountsForUpdate,
          }
        );
      }

      const settingsResponse = await saveIntegrationEngineSettings({
        entityId,
        settings: settingsInput,
        disableReload: true,
      });

      if (settingsResponse?.data?.success) {
        await getIntegrationEngineSettings();
        onContinue();
      } else {
        Notify.error(settingsResponse?.data?.message || '');
        setIsLoading(false);
      }
    } catch (error: any) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  if (isLoadingAccounts) {
    return <TitleLoader title={`Getting Xero accounts.`} />;
  }

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        Required HedgeFlows accounts in Xero
      </Title>

      <Col mb gap={theme.spacing.xs}>
        <Paragraph>
          We automatically create accounts in Xero to record your payments and
          collections:
        </Paragraph>

        <UnorderedList
          data={[
            'Invoices in foreign currencies paid using Pounds Sterling funds are processed via “HedgeFlows GBP”',
            'Invoices issued and paid using funds in the same currency are process via a relevant HedgeFlows currency account.',
          ]}
        />
      </Col>

      {accounts.length > 0 && (
        <Col mb gap={theme.spacing.xs}>
          <Paragraph>The following accounts already exist in Xero: </Paragraph>
          <Row
            flexWrap="wrap"
            gap={theme.spacing.s}
            justifyContent="flex-start"
          >
            {accounts.map(({ name }) => (
              <Pill text={name} />
            ))}
          </Row>
        </Col>
      )}

      <StepAccountsSecondNotification>
        <Icon icon="alert-ico" />
        <Paragraph ml>
          By pressing Confirm you confirm your permission for HedgeFlows to
          update Xero on your behalf. We will automatically record in Xero
          transactions that you process via HedgeFlows.
        </Paragraph>
      </StepAccountsSecondNotification>

      <Button
        isLoading={isLoading}
        disabled={isLoading}
        mt
        mtValue={theme.spacing.xl}
        onClick={onSubmit}
      >
        Confirm
      </Button>
    </>
  );
};

export default StepAccountsSecondXero;
