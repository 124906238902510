import { openQuery } from 'utils';
import firestore from './firestore';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';

interface SubscribeToTransactionsParams {
  entityId: string;
  limit?: number;
  callback: (transactions: any) => void;
}

export const subscribeToTransactions = ({
  entityId,
  limit = 250,
  callback,
}: SubscribeToTransactionsParams) => {
  return firestore
    .collection('transactions')
    .where('_owner', '==', entityId)
    .orderBy('_created', 'desc')
    .limit(limit)
    .onSnapshot((transactions) => callback(openQuery(transactions)));
};

export const getTransactionsCsvByCurrency = async (
  currencies: string,
  dateFrom: string,
  dateTo: string
) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<Array<{ currency: string; csv: string }>>
  >(
    `/transactions/csv?currencies=${currencies}&dateFrom=${dateFrom}&dateTo=${dateTo}`
  );

export const getTransactionCurrencies = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<string[]>>(
    `/transactions/currencies`
  );
