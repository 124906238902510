import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Button, Paragraph, PermissionsChecker, Row } from 'components';
import { IInvoiceFromSearch } from 'types';
import InvoiceNumberCell from 'components/shared/InvoiceNumberCell/InvoiceNumberCell';
import { InvoiceFromSearchStatusCell } from 'components/shared/InvoicesTable/components/Cells/Cells';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import {
  getInvoiceRemainingAmount,
  isInvoiceFromSearchApprovableByUser,
  isInvoiceFromSearchSelectable,
} from 'utils/invoices';
import DateCell from 'components/shared/DateCell/DateCell';
import { isDateOverdue } from 'utils/dates';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { useStoreState } from 'state';
import { MobileInvoiceCard } from 'components/shared/MobileInvoiceCard/MobileInvoiceCard.styles';

interface IOwnProps {
  invoice: IInvoiceFromSearch;
  isPaid: boolean;
  isSubmitted: boolean;
  selectedInvoices: IInvoiceFromSearch[];
  onSelectInvoices?: (invoices: IInvoiceFromSearch[]) => void;
  selectable?: boolean;
  removeInvoiceFromPaymentRun?: (paymentRunInvoiceId: string) => Promise<void>;
  isUpdatingPaymentRun?: boolean;
  hasPaymentRun?: boolean;
  style?: CSSProperties;
}

const MobileInvoicesTile: FC<IOwnProps> = ({
  invoice,
  style,
  isPaid,
  isSubmitted,
  selectedInvoices,
  onSelectInvoices,
  selectable,
  removeInvoiceFromPaymentRun,
  isUpdatingPaymentRun,
  hasPaymentRun,
}) => {
  const theme = useTheme();
  const { userId, isUserApprover } = useStoreState(
    ({ UserState }) => UserState
  );
  const isOverdue = useMemo(() => isDateOverdue(invoice.dueDate), [
    invoice.dueDate,
  ]);
  const isChecked = useMemo(
    () =>
      selectedInvoices.some(
        (selectedInvoice) => selectedInvoice.id === invoice.id
      ),
    [invoice.id, selectedInvoices]
  );
  const isSelectable = useMemo(() => {
    if (!selectable) {
      return false;
    }

    if (isSubmitted) {
      return (
        isInvoiceFromSearchSelectable(invoice) &&
        isUserApprover &&
        isInvoiceFromSearchApprovableByUser(invoice, userId)
      );
    }

    return isInvoiceFromSearchSelectable(invoice);
  }, [invoice, selectable, userId, isUserApprover, isSubmitted]);

  const onSelect = useCallback(() => {
    if (!isSelectable || !onSelectInvoices) {
      return;
    }

    if (!isChecked) {
      onSelectInvoices([...selectedInvoices, invoice]);
    } else {
      onSelectInvoices(
        selectedInvoices.filter((selectedInvoice) => {
          return selectedInvoice.id !== invoice.id;
        })
      );
    }
  }, [invoice, isChecked, isSelectable, onSelectInvoices, selectedInvoices]);

  return (
    <MobileInvoiceCard
      padding={theme.spacing.m}
      gap={theme.spacing.m}
      isOverdue={isOverdue}
      onClick={onSelect}
      color={isChecked ? 'emeraldLight' : 'white'}
      style={style}
    >
      <Row>
        <Row flex={1}>
          <InvoiceNumberCell record={invoice} />
          <InvoiceFromSearchStatusCell invoice={invoice} />
        </Row>

        {isSelectable && (
          <Row ml>
            <Checkbox
              onChange={onSelect}
              checked={isChecked}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </Row>
        )}

        {hasPaymentRun && !!removeInvoiceFromPaymentRun && (
          <PermissionsChecker action="update" resource="payment_runs">
            <Button
              variant="link"
              icon="remove-ico"
              disabled={isUpdatingPaymentRun}
              onClick={() => removeInvoiceFromPaymentRun(invoice.id)}
            />
          </PermissionsChecker>
        )}
      </Row>

      <Row>
        <AmountCell
          size="large"
          amount={getInvoiceRemainingAmount(invoice)}
          currencyCode={invoice.currency}
        />
        <Row gap={theme.spacing.xs}>
          {isPaid && invoice.fullyPaidOnDate && (
            <>
              <Paragraph>paid</Paragraph>
              <DateCell value={invoice.fullyPaidOnDate} />
            </>
          )}

          {!isPaid && (
            <>
              <Paragraph color={isOverdue ? 'red' : 'dark'}>due</Paragraph>
              <DateCell withOverdueMarker value={invoice.dueDate} />
            </>
          )}
        </Row>
      </Row>
    </MobileInvoiceCard>
  );
};

export default MobileInvoicesTile;
