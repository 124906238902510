import { FC } from 'react';
import { useTheme } from 'styled-components';
import { IPaymentRunBreakdownByRecipientInvoice } from 'types/paymentRuns';
import { Popup, Col, Title } from 'components';
import MobileInvoicesTile from '../MobileInvoicesTile/MobileInvoicesTile';

interface IOwnProps {
  invoices: IPaymentRunBreakdownByRecipientInvoice[];
  onRemoveInvoice: (paymentRunInvoiceId: string) => Promise<void>;
  onClose: () => void;
  recipientName: string;
  isUpdatingPaymentRun?: boolean;
}

const MobileInvoicesPopup: FC<IOwnProps> = ({
  invoices,
  onClose,
  onRemoveInvoice,
  recipientName,
  isUpdatingPaymentRun,
}) => {
  const theme = useTheme();
  return (
    <Popup
      onClose={onClose}
      HeaderContent={
        <Title variant="h3">
          {recipientName} invoice{invoices.length > 1 && "'s"}
        </Title>
      }
    >
      <Col mt mtValue={theme.spacing.xxs} gap={theme.spacing.xxs} flex={1}>
        {invoices.map((invoice) => (
          <MobileInvoicesTile
            key={invoice.id}
            invoice={invoice}
            isUpdatingPaymentRun={isUpdatingPaymentRun}
            onRemoveInvoice={onRemoveInvoice}
          />
        ))}
      </Col>
    </Popup>
  );
};

export default MobileInvoicesPopup;
