import {
  IInvoice,
  IResponse,
  IInvoiceApproval,
  IInvoiceFromSearch,
  IRecipient,
  TInvoicesAggregations,
  IGroupedInvoicesFromSearch,
  IInvoiceDetailsFromAttachment,
} from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { openDoc, openQuery } from 'utils';
import db from 'services/firestore';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import _chunk from 'lodash.chunk';
import firebase from 'firebase/compat/app';
import { ISearchResponse } from 'types/search';
import { IManualInvoice } from 'pages/ManualUpload/hooks/useBulkUploadCSVInvoices';
import { generateSearchQuery } from 'utils/common';
import { AxiosResponse } from 'axios';

export interface GetInvoiceParams {
  invoiceId: string;
}
/** @oleksiikiselovartkai mentioning we should look at switching this to retrieving the invoice from ES instead. */
export const getInvoiceById = async ({ invoiceId }: GetInvoiceParams) =>
  db
    .collection('invoices')
    .doc(invoiceId)
    .get()
    .then((doc) => openDoc<IInvoice>(doc));

export const getDetailedInvoice = async ({ invoiceId }: GetInvoiceParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse<IInvoice>>(
    `/invoices/${invoiceId}/detailed`
  );
};

export interface SetXeroPermissionsParams extends GetInvoiceParams {
  permission: boolean;
}

export interface SubscribeToInvoiceParams {
  invoiceId: string;
  callback: (invoice: IInvoice) => void;
}

export const subscribeToInvoice = ({
  invoiceId,
  callback,
}: SubscribeToInvoiceParams) => {
  return db
    .collection('invoices')
    .doc(invoiceId)
    .onSnapshot((doc) => callback(openDoc(doc) as IInvoice));
};

/**
 * This endpoint can return undefined!
 */
export const getInvoiceExternalContact = async (invoiceId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IRecipient>>(
    `/invoices/${invoiceId}/contact/external`
  );

export interface BindContractRateToInvoiceParams {
  contractId: string;
  payload: {
    invoiceIds: string[];
  };
}

export const bindContractRateToInvoice = async ({
  contractId,
  payload,
}: BindContractRateToInvoiceParams) => {
  return AxiosPrivateFirebaseInstance.post<IResponse<IInvoice[]>>(
    `/contracts/${contractId}/usepartial`,
    payload
  );
};

export const unbindContractRateToInvoice = async ({
  contractId,
  payload,
}: {
  contractId: string;
  payload: {
    invoiceId: string;
  };
}) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/contracts/${contractId}/releasepartial`,
    payload
  );
};

export interface CancelInvoiceContractRateParams {
  contractId: string;
  payload: {
    cancelValue: number;
  };
}

export const cancelInvoiceContractRate = async ({
  contractId,
  payload,
}: CancelInvoiceContractRateParams) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/contracts/${contractId}/cancel`,
    payload
  );
};

export interface GetContractRateCancelValueParams {
  contractId: string;
}

export const getContractRateCancelValue = async ({
  contractId,
}: GetContractRateCancelValueParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `/contracts/${contractId}/cancelvalue`
  );
};

export interface CreateInvoiceTrackingParams {
  invoiceId: string;
  trackingData: {
    sellCurrency: string;
    buyCurrency: string;
    sellAmount: number;
    targetAmount: number;
    targetRate: number;
  };
}

export const createInvoiceTracking = async ({
  invoiceId,
  trackingData,
}: CreateInvoiceTrackingParams) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/tracking/${invoiceId}`,
    trackingData
  );
};

export interface DeleteInvoiceTrackingParams {
  trackingId: string;
}

export const deleteInvoiceTracking = async ({
  trackingId,
}: DeleteInvoiceTrackingParams) => {
  return AxiosPrivateFirebaseInstance.delete<IResponse>(
    `/tracking/${trackingId}`
  );
};

export interface IDeleteInvoiceParams {
  invoiceId: string;
}

export const deleteInvoice = async ({ invoiceId }: IDeleteInvoiceParams) =>
  AxiosPrivateFirebaseInstance.delete<IResponse>(`/invoices/${invoiceId}`);

export const getInvoicesAsCsvFile = async (tab: 'paid' | 'outstanding') =>
  AxiosPrivateFirebaseInstance.get<string>(`/invoices/csv?status=${tab}`);

export interface UpdateInvoiceTrackingParams {
  trackingId: string;
  trackingData: {
    sellCurrency: string;
    buyCurrency: string;
    sellAmount: number;
    targetAmount: number;
    targetRate: number;
  };
}

export const updateInvoiceTracking = async ({
  trackingId,
  trackingData,
}: UpdateInvoiceTrackingParams) => {
  return AxiosPrivateFirebaseInstance.put<IResponse>(
    `/tracking/${trackingId}`,
    trackingData
  );
};
export interface GetInvoiceTrackingParams {
  trackingId: string;
}

export const getInvoiceTracking = async ({
  trackingId,
}: GetInvoiceTrackingParams) => {
  return db
    .collection('tracking')
    .doc(trackingId)
    .get()
    .then((doc) => openDoc(doc));
};

export const bulkCsvInvoicesUpload = async (
  payload?: FormData | IManualInvoice[]
) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/invoices/import`,
    payload
  );
};

interface IUpdateInvoiceParamsBase {
  id: string;
  data: Partial<IInvoice>;
  updateForSameContact?: boolean;
}

export function updateInvoice(
  params: { transformToElastic: true } & IUpdateInvoiceParamsBase
): Promise<AxiosResponse<IResponse<IInvoiceFromSearch[]>>>;
export function updateInvoice(
  params: { transformToElastic?: false } & IUpdateInvoiceParamsBase
): Promise<AxiosResponse<IResponse<IInvoice[]>>>;
export async function updateInvoice({
  id,
  data,
  updateForSameContact,
  transformToElastic,
}: {
  id: string;
  data: Partial<IInvoice>;
  updateForSameContact?: boolean;
  transformToElastic?: boolean;
}) {
  return AxiosPrivateFirebaseInstance.put<
    IResponse<IInvoice[] | IInvoiceFromSearch[]>
  >(
    `/invoices/${id}${generateSearchQuery({
      updateForSameContact,
      transformToElastic,
    })}`,
    data
  );
}

export interface UpdateInvoiceApprovalStatusParams {
  invoiceIds: string[];
  approvalStatus: IInvoiceApproval['status'] | 'rejected';
}

export const updateInvoicesApprovalStatus = async ({
  invoiceIds,
  approvalStatus,
}: UpdateInvoiceApprovalStatusParams) => {
  return AxiosPrivateFirebaseInstance.put<IResponse>(
    '/invoices/approval/update',
    {
      approvalStatus,
      invoiceIds,
    }
  );
};

export const getInvoicesByIdArray = async (
  payload?: string[]
): Promise<IInvoice[]> => {
  if (!payload || payload.length === 0) {
    return [];
  }
  if (payload.length > 10) {
    const result = await Promise.all<IInvoice[]>(
      _chunk(payload, 10).map((arr: any) =>
        db
          .collection('invoices')
          .where(firebase.firestore.FieldPath.documentId(), 'in', arr)
          .get()
          .then((query) => openQuery(query))
      )
    );
    return result.flat();
  } else {
    return db
      .collection('invoices')
      .where(firebase.firestore.FieldPath.documentId(), 'in', payload)
      .get()
      .then((query) => openQuery(query));
  }
};

export const getInvoicesPaginated = async (query: string) =>
  AxiosPrivateFirebaseInstance.get<
    | IResponse<ISearchResponse<IInvoiceFromSearch>>
    | IResponse<ISearchResponse<IGroupedInvoicesFromSearch>>
  >(`/invoices${query}`);

export const getInvoicesAggregations = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<TInvoicesAggregations>>(
    `/invoices/aggregations`
  );

export const getInvoicesCount = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<{ count: number }>>(
    `/invoices/aggregations/count`
  );

export const getInvoicesFees = async (invoiceIds: string[]) =>
  AxiosPrivateFirebaseInstance.post<
    IResponse<{
      value: number;
      currencyCode: string;
    }>
  >('/invoices/fees', {
    invoiceIds,
  });

export const subscribeToEntityInvoicesWrites = (
  entityId: string,
  callback: () => Promise<void> | undefined
) =>
  db
    .collection('invoices')
    .where('_owner', '==', entityId)
    .orderBy('_updated', 'desc')
    .limit(10)
    .onSnapshot(callback);

/**
 * Returns attachment as base64 encoded string
 */
export const getInvoiceAttachmentContent = async ({
  invoiceId,
  attachmentId,
}: {
  invoiceId: string;
  attachmentId: string;
}) => {
  const params = new URLSearchParams();

  return AxiosPrivateFirebaseInstance.get<{
    data: string;
  }>(`/invoices/${invoiceId}/attachments/${attachmentId}/content`, { params });
};

export const getInvoiceDetailsFromAttachments = async (invoiceId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IInvoiceDetailsFromAttachment>>(
    `/invoices/${invoiceId}/attachments/ocr`
  );

export const unlinkInvoiceContact = async (invoiceId: string) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IInvoiceFromSearch[]>>(
    `/invoices/${invoiceId}/contact/unlink${generateSearchQuery({
      transformToElastic: true,
    })}`
  );
