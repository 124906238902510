import styled from 'styled-components';
import { Col } from 'components';

export const ReviewStepFormWrapper = styled(Col)`
  background-color: ${({ theme }) => theme.color.white};
  gap: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.xxs};
  padding: ${({ theme }) => theme.spacing.m};
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
`;
