import { useQuery } from 'hooks';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import { useCallback, useState } from 'react';
import * as Firebase from 'services/firebase';
import { saveIntegrationEngineSettings } from 'services/firebase';

import { useStoreActions, useStoreState } from 'state';
import { IIntegrationPermissions, IIntegrationSettingsInput } from 'types';
import { Notify } from 'utils';

const setDefaultPermissionsAndSettings = async ({
  entityId,
}: {
  entityId: string;
}) => {
  try {
    // no write permissions as default
    const permissions: IIntegrationPermissions = {
      global: false,
      invoices: false,
      payments: false,
      purchaseOrders: false,
      bills: false,
      bankFees: false,
      importInvoices: true,
      importBills: true,
      importPayments: true,
      importContactDetails: true,
      importPurchaseAndSalesOrders: false,
      importBankBalances: false,
    };

    const settingsInput: IIntegrationSettingsInput = {
      settings: {
        monthsInThePastToQueryFor: 24,
        excludedCurrencies: [],
      },
      permissions,
    };

    await saveIntegrationEngineSettings({
      entityId,
      settings: settingsInput,
      disableReload: true,
    });
  } catch (error: any) {
    throw new Error(error?.response.data?.message ?? '');
  }
};

const useNewClientXero = () => {
  const URLQuery = useQuery();
  const [isCreatingEntity, setIsCreatingEntity] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [companyName, setCompanyName] = useState<string>();
  const { userId } = useStoreState(({ UserState }) => UserState);
  const { getUser } = useStoreActions(({ UserState }) => UserState);

  const { onImportIntegrationEngineData } = useIntegrationEngine();

  const createNewClientEntity = useCallback(async () => {
    try {
      const firstName = URLQuery.get('firstName');
      const lastName = URLQuery.get('lastName');
      const organisationName = URLQuery.get('organisationName');
      const organisationCountryCode = URLQuery.get('organisationCountryCode');
      const email = URLQuery.get('email');
      const entityId = URLQuery.get('entityId');

      if (
        !firstName ||
        !lastName ||
        !organisationName ||
        !organisationCountryCode ||
        !email ||
        !entityId
      ) {
        throw new Error('Missing required fields');
      }

      setIsCreatingEntity(true);

      // 1. Create the entity
      const response = await Firebase.createAnotherEntityForExistingUser({
        firstName,
        lastName,
        email,
        organisationCountryCode,
        organisationName,
        entityId,
      });

      if (!response.data.data) {
        return;
      }

      const { companyName } = response.data.data;

      // 2. Update entity on user so future calls work
      await Firebase.updateUser({
        user: {
          entityId,
        },
      });

      // 3. Update entity details stored in FE state
      if (userId) {
        await getUser({ id: userId });
      }

      // 4. Set default settings
      await setDefaultPermissionsAndSettings({
        entityId,
      });

      // 5. Kick of a data async import; pass in new entityId as entityId stored in state might not have updated to latest version yet
      await onImportIntegrationEngineData(entityId);

      setIsCompleted(true);
      setIsCreatingEntity(false);
      setCompanyName(companyName);
    } catch (error: any) {
      Notify.error(error.response?.data?.error ?? error?.message ?? error);
      setIsCreatingEntity(false);
      setIsError(true);
    }
  }, [URLQuery, getUser, onImportIntegrationEngineData, userId]);

  return {
    createNewClientEntity,
    companyName,
    isCompleted,
    isCreatingEntity,
    isError,
  };
};

export default useNewClientXero;
