import {
  IInvoice,
  IInvoiceApproval,
  IInvoiceFromSearch,
  INVOICE_STATUSES,
  IRateContract,
  IRecipient,
  ISourceSyncData,
  ITransfer,
  Nullable,
  RECIPIENT_STATUS,
  TInvoicesAggregations,
} from 'types';
import dayjs from 'dayjs';
import { isDateOverdue } from './dates';
import { groupBy } from 'lodash';
import { IPaymentRunCurrencyTotal } from 'types/paymentRuns';
import { isContactValid } from './recipient';
import { firstCharacterToUppercase } from './common';
import { ITableFilter } from 'components/shared/Filters/types';
import { DATE_FORMAT } from 'variables';

// invoices from search
export const isInvoiceFromSearchNotPaid = (invoice: IInvoiceFromSearch) =>
  invoice.status !== INVOICE_STATUSES.paid;

export const isInvoiceFromSearchProcessingPayment = (
  invoice: IInvoiceFromSearch
) => invoice.status === INVOICE_STATUSES.processingPayment;

export const isInvoiceFromSearchFromXero = (invoice: IInvoiceFromSearch) =>
  invoice.externalRefsSourceSystem === 'xero';

export const isInvoiceFromSearchHasValidContact = (
  invoice: IInvoiceFromSearch
) =>
  !!invoice.contactId &&
  !!invoice.contactEnabled &&
  !invoice.contactShouldPausePayments;

export const isInvoiceFromSearchApprovable = (invoice: IInvoiceFromSearch) =>
  isPayableInvoice(invoice) &&
  isInvoiceFromSearchHasValidContact(invoice) &&
  isInvoiceStatusInPayableState(invoice) &&
  invoice.approvalStatus === 'submitted';

export const isInvoiceFromSearchSubmittableForReview = (
  invoice: IInvoiceFromSearch
) =>
  isPayableInvoice(invoice) &&
  isInvoiceFromSearchHasValidContact(invoice) &&
  isInvoiceStatusInPayableState(invoice) &&
  !invoice.approvalStatus;

export const isInvoiceFromSearchSelectable = (invoice: IInvoiceFromSearch) =>
  invoice.status !== INVOICE_STATUSES.paid &&
  !isInvoiceDisabled(invoice) &&
  isInvoiceFromSearchHasValidContact(invoice) &&
  !isReceivableInvoice(invoice) &&
  isInvoiceStatusInPayableState(invoice);

export const isInvoiceRowSelectable = (
  invoice: IInvoice,
  recipientById: (id: Nullable<string>) => IRecipient | undefined
) =>
  isContactValid(recipientById(invoice.contactId)) &&
  invoice.status !== INVOICE_STATUSES.paid &&
  !isInvoiceDisabled(invoice) &&
  !isReceivableInvoice(invoice) &&
  isInvoiceStatusInPayableState(invoice);

interface IIsApprovalFlowAllowToPayInvoiceFromSearchParams {
  hasApprovalFlow: boolean;
  record: IInvoiceFromSearch;
}

export const isApprovalFlowAllowToPayInvoiceFromSearch = ({
  hasApprovalFlow,
  record,
}: IIsApprovalFlowAllowToPayInvoiceFromSearchParams) =>
  !hasApprovalFlow || (hasApprovalFlow && record.approvalStatus === 'approved');

export const isInvoiceFromSearchApprovableByUser = (
  invoice: IInvoiceFromSearch,
  userId: Nullable<string>
) =>
  isInvoiceFromSearchApprovable(invoice) &&
  !!userId &&
  !!invoice.approvalApprovers?.includes(userId) &&
  invoice.approvedBy !== userId;

export const isInvoiceApprovable = (invoice: IInvoice) =>
  isPayableInvoice(invoice) &&
  isInvoiceStatusInPayableState(invoice) &&
  invoice.approval?.status === 'submitted';

export const isInvoiceApprovableByUser = (
  invoice: IInvoice,
  userId: Nullable<string>
) =>
  isInvoiceApprovable(invoice) &&
  !!userId &&
  invoice.approval?.approvedBy !== userId;

export const isInvoiceSubmittableForReview = (invoice: IInvoice) =>
  isPayableInvoice(invoice) &&
  isInvoiceStatusInPayableState(invoice) &&
  !invoice.approval?.status;

export const isInvoiceOverdue = (invoice: IInvoice) =>
  isDateOverdue(invoice.dueDate) &&
  isPayableInvoice(invoice) &&
  invoice.status !== INVOICE_STATUSES.paid;

interface IIsApprovalFlowAllowToPayInvoiceParams {
  hasApprovalFlow: boolean;
  record: IInvoice;
  isUserApprover: boolean;
}

export const isApprovalFlowAllowToPayInvoice = ({
  hasApprovalFlow,
  record,
}: IIsApprovalFlowAllowToPayInvoiceParams) =>
  !hasApprovalFlow ||
  (hasApprovalFlow && record.approval?.status === 'approved');

export const isInvoiceDueIn14Days = (invoice: IInvoice) => {
  const daysLeft = getInvoiceDaysLeft(invoice);

  return (
    daysLeft <= 14 &&
    daysLeft > 0 &&
    isPayableInvoice(invoice) &&
    invoice.status !== INVOICE_STATUSES.paid
  );
};

export const isInvoiceNotPaid = (invoice: IInvoice) =>
  invoice.status !== INVOICE_STATUSES.paid;

export const isInvoiceFromXero = (invoice: IInvoice) =>
  invoice.externalRefs?.sourceSystem === 'xero';

export const getInvoiceDaysLeft = (invoice: IInvoice | IInvoiceFromSearch) =>
  dayjs(invoice.dueDate).endOf('day').diff(dayjs().startOf('day'), 'days');

export const isReceivableInvoice = (invoice: IInvoice | IInvoiceFromSearch) =>
  invoice.type === 'Receivable';

export const isPayableInvoice = (invoice: IInvoice | IInvoiceFromSearch) =>
  invoice.type === 'Payable';

export const isInvoiceStatusInPayableState = (
  invoice: IInvoice | IInvoiceFromSearch
) =>
  invoice.status === INVOICE_STATUSES.authorised ||
  invoice.status === INVOICE_STATUSES.submitted ||
  invoice.status === INVOICE_STATUSES.partiallyPaid;

export const isInvoiceDisabled = (invoice: IInvoice | IInvoiceFromSearch) =>
  invoice.status === INVOICE_STATUSES.voided ||
  invoice.status === INVOICE_STATUSES.deleted ||
  !!invoice.excludeFromRisk;

export const isInvoicePrebookable = (
  invoice: IInvoice | IInvoiceFromSearch,
  entityCurrencyCode: string
) =>
  invoice.currency !== entityCurrencyCode &&
  !invoice?.contractId &&
  !invoice.trackingId &&
  ((isPayableInvoice(invoice) && isInvoiceStatusInPayableState(invoice)) ||
    isReceivableInvoice(invoice));

export const isInvoiceTrackable = (invoice: IInvoice | IInvoiceFromSearch) =>
  invoice.status !== INVOICE_STATUSES.paymentScheduled;

export const isInvoiceEditable = (invoice: IInvoiceFromSearch) =>
  [INVOICE_STATUSES.draft, INVOICE_STATUSES.authorised].includes(
    invoice.status
  ) && isHedgeflowsInvoice(invoice);

export const getInvoiceRemainingAmount = (
  invoice: IInvoice | IInvoiceFromSearch
) => invoice.amountDue || invoice.totalAmount;

export const getInvoiceNumber = (invoice: IInvoice | IInvoiceFromSearch) =>
  invoice?.invoiceNumber || '"no ref"';

export const getInvoiceApprovalNotificationMessage = (
  approvalStatus: IInvoiceApproval['status'] | 'rejected',
  isMoreThenOneInvoice: boolean
) => {
  const invoiceMsg = isMoreThenOneInvoice ? 'Invoices have' : 'Invoice has';

  if (approvalStatus === 'submitted') {
    return `${invoiceMsg} been submitted for approval`;
  }
  if (approvalStatus === 'approved') {
    return `${invoiceMsg} been approved`;
  }

  return `${invoiceMsg} been rejected`;
};

interface IGetInvoiceSyncedToExternalSourceStatusDetailsProps {
  lastSyncToExternalSource?: ISourceSyncData;
}

export const getInvoiceSyncedToExternalSourceStatusDetails = ({
  lastSyncToExternalSource,
}: IGetInvoiceSyncedToExternalSourceStatusDetailsProps) => {
  if (!lastSyncToExternalSource) {
    return null;
  }

  const { status, success } = lastSyncToExternalSource;

  if (status === 'success' || success) {
    return {
      icon: 'done-ico',
      color: 'emeraldDark',
    };
  }
  if (status === 'error' || success === false) {
    return {
      icon: 'failed-ico',
      color: 'red',
    };
  }
  if (status === 'queued') {
    return {
      icon: 'clock-ico',
      color: 'yellow',
    };
  }
  return null;
};

export const getInvoiceFromSearchSyncedToExternalSourceStatusDetails = ({
  lastSyncToExternalSourceStatus,
}: {
  lastSyncToExternalSourceStatus?: ISourceSyncData['status'];
}) => {
  if (!lastSyncToExternalSourceStatus) {
    return null;
  }

  if (lastSyncToExternalSourceStatus === 'success') {
    return {
      icon: 'done-ico',
      color: 'emeraldDark',
    };
  }
  if (lastSyncToExternalSourceStatus === 'error') {
    return {
      icon: 'failed-ico',
      color: 'red',
    };
  }
  if (lastSyncToExternalSourceStatus === 'queued') {
    return {
      icon: 'clock-ico',
      color: 'yellow',
    };
  }
  return null;
};

export const getCurrencyTotalsFromInvoices = ({
  invoices,
  transferById,
  rateContractById,
}: {
  invoices: IInvoiceFromSearch[];
  transferById: (transferId?: Nullable<string>) => ITransfer | null;
  rateContractById: (id: Nullable<string>) => IRateContract | null;
}) => {
  const resultsByCurrency = groupBy<IInvoiceFromSearch>(invoices, 'currency');

  return Object.keys(resultsByCurrency).reduce<
    Pick<
      IPaymentRunCurrencyTotal,
      'amount' | 'currency' | 'amountInLocalCurrency'
    >[]
  >((total, currency) => {
    const invoicesByCurrency = resultsByCurrency[currency];

    const { amount, amountInLocalCurrency } = invoicesByCurrency.reduce<
      Pick<IPaymentRunCurrencyTotal, 'amount' | 'amountInLocalCurrency'>
    >(
      (accumulator, invoice) => {
        const { transferId, contractId, invoiceRate } = invoice;
        let rateToUse = invoiceRate;

        if (transferId) {
          rateToUse = transferById(transferId)?.rate ?? invoiceRate;
        }
        if (contractId) {
          rateToUse = rateContractById(contractId)?.rate ?? invoiceRate;
        }

        const invoiceAmount = getInvoiceRemainingAmount(invoice);

        if (rateToUse) {
          return {
            amount: accumulator.amount + invoiceAmount,
            amountInLocalCurrency:
              accumulator.amountInLocalCurrency + invoiceAmount / rateToUse,
          };
        }

        return {
          amount: accumulator.amount + invoiceAmount,
          amountInLocalCurrency:
            accumulator.amountInLocalCurrency + invoiceAmount,
        };
      },
      { amount: 0, amountInLocalCurrency: 0 }
    );

    return [
      ...total,
      {
        currency,
        amount,
        amountInLocalCurrency,
      },
    ];
  }, []);
};

// TODO: Consider renaming `externalRefsSourceSystem` from `csvUpload` to `manualUpload` for clarity.
export const isHedgeflowsInvoice = (invoice: IInvoiceFromSearch) =>
  invoice.externalRefsSourceSystem === 'csvUpload';

export const getManualInvoiceTypeFromInvoice = (
  invoice: IInvoiceFromSearch
) => {
  if (isPayableInvoice(invoice)) {
    return 'Bill';
  }

  return 'Invoice';
};

export const getSourceSystemContactFromInvoiceFromSearch = (
  invoice?: IInvoiceFromSearch | null
): Partial<IRecipient> => ({
  recipientName: invoice?.contactRecipientName,
  currency: invoice?.currency,
  status: RECIPIENT_STATUS.draft,
  externalRefs: {
    sourceSystem: invoice?.externalRefsSourceSystem,
    sourceSystemId: invoice?.externalRefsSourceSystemContactId,
  },
});

export const getStatusTextFromInvoice = (
  invoice: IInvoice,
  requiredNumberOfApprovalsOnUserEntity: number
) => {
  if (invoice.approval?.status) {
    if (invoice.approval?.status === 'submitted') {
      return `${
        invoice.approval?.approvedBy
          ? requiredNumberOfApprovalsOnUserEntity - 1
          : requiredNumberOfApprovalsOnUserEntity
      } Approval(s) pending`;
    } else {
      return firstCharacterToUppercase(invoice.approval?.status);
    }
  } else {
    return 'Needs approval';
  }
};

const validateFilterOptionDate = (date?: string) =>
  date && dayjs(date, DATE_FORMAT).isValid() ? date : '';

export const generateDateUrlValuePayload = (filters: ITableFilter[]) => {
  const urlValuePayload: Record<string, string> = {};
  const dateFilter = filters.find((item) => item.id === 'date');

  if (!dateFilter) {
    return urlValuePayload;
  }

  const dueDateFromFilterOptionValue = dateFilter.value.find(
    (filterOption) => filterOption.id === 'dueDateFrom'
  )?.value;

  urlValuePayload.dueDateFrom = validateFilterOptionDate(
    dueDateFromFilterOptionValue
  );

  const dueDateToFilterOptionValue = dateFilter.value.find(
    (filterOption) => filterOption.id === 'dueDateTo'
  )?.value;

  urlValuePayload.dueDateTo = validateFilterOptionDate(
    dueDateToFilterOptionValue
  );

  const fullyPaidOnDateFromFilterOptionValue = dateFilter.value.find(
    (filterOption) => filterOption.id === 'fullyPaidOnDateFrom'
  )?.value;

  urlValuePayload.fullyPaidOnDateFrom = validateFilterOptionDate(
    fullyPaidOnDateFromFilterOptionValue
  );

  const fullyPaidOnDateToFilterOptionValue = dateFilter.value.find(
    (filterOption) => filterOption.id === 'fullyPaidOnDateTo'
  )?.value;

  urlValuePayload.fullyPaidOnDateTo = validateFilterOptionDate(
    fullyPaidOnDateToFilterOptionValue
  );

  return urlValuePayload;
};

export const getCountFromInvoicesAggregationPerFilter = (
  invoicesAggregations: TInvoicesAggregations | null,
  filterName: keyof TInvoicesAggregations['perFilter']
) => invoicesAggregations?.perFilter[filterName]?.count ?? 0;
