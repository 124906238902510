import { Col, Row, StaleNotification } from 'components';
import Button from 'components/shared/Button/Button';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import {
  AuthoriseStepFooterWrapper,
  AuthoriseStepCurrencyTotalsWrapper,
  RedHint,
} from './AuthoriseStepFooter.styles';
import useDeviceWidth from 'hooks/useDeviceWidth';
import FooterControls, {
  IFooterControlsProps,
} from '../FooterControls/FooterControls';
import { IPaymentRun } from 'types/paymentRuns';
import { useHistory } from 'react-router';
import { StyledForm } from 'components/shared/Form/Form.styles';

export interface IAuthoriseStepFooterProps extends IFooterControlsProps {
  paymentRunError?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  paymentRun: IPaymentRun;
  setShowConfirmPopup: (show: boolean) => void;
}

const AuthoriseStepFooter: FC<IAuthoriseStepFooterProps> = ({
  control,
  errors,
  paymentRunError,
  onSubmit,
  paymentRun,
  setShowConfirmPopup,
  defaultActiveStartDate,
  minDate,
  nonTradingDaysToUse,
  isBuyFxOn,
  onBuyFxAll,
  isLoading,
  isLoadingBuyFxAll,
  canSelectBuyFx,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const { isMobile } = useDeviceWidth();

  return (
    <Col>
      {paymentRunError && (
        <Row>
          <StaleNotification
            title="Issue with the transfers"
            cross={false}
            bgColor={theme.color.red}
            style={{ maxWidth: 'unset' }}
          >
            {paymentRunError}
          </StaleNotification>
        </Row>
      )}
      <AuthoriseStepFooterWrapper
        gap={theme.spacing.m}
        justifyContent="space-between"
      >
        <StyledForm id="payment-run-authorise-form" onSubmit={onSubmit}>
          <Col alignItems="flex-start">
            {!isMobile && (
              <Row mb>
                <FooterControls
                  control={control}
                  errors={errors}
                  defaultActiveStartDate={defaultActiveStartDate}
                  minDate={minDate}
                  nonTradingDaysToUse={nonTradingDaysToUse}
                  isBuyFxOn={isBuyFxOn}
                  onBuyFxAll={onBuyFxAll}
                  isLoading={isLoading}
                  isLoadingBuyFxAll={isLoadingBuyFxAll}
                  canSelectBuyFx={canSelectBuyFx}
                />
              </Row>
            )}

            {!!paymentRun.valid ? (
              <Button type="button" onClick={() => setShowConfirmPopup(true)}>
                Confirm
              </Button>
            ) : (
              <Button onClick={history.goBack} type="button">
                Back to review
              </Button>
            )}
          </Col>
        </StyledForm>

        <AuthoriseStepCurrencyTotalsWrapper>
          <InvoicesCurrencyTotals
            title="Funds required:"
            currencyTotals={paymentRun.paymentRunTotals}
          />

          <RedHint>
            You can purchase FX now or anytime prior to the payment date
          </RedHint>
        </AuthoriseStepCurrencyTotalsWrapper>

        {isMobile && (
          <Col mb gap={theme.spacing.m}>
            <FooterControls
              control={control}
              errors={errors}
              defaultActiveStartDate={defaultActiveStartDate}
              minDate={minDate}
              nonTradingDaysToUse={nonTradingDaysToUse}
              isBuyFxOn={isBuyFxOn}
              onBuyFxAll={onBuyFxAll}
              isLoading={isLoading}
              isLoadingBuyFxAll={isLoadingBuyFxAll}
              canSelectBuyFx={canSelectBuyFx}
            />
          </Col>
        )}
      </AuthoriseStepFooterWrapper>
    </Col>
  );
};

export default AuthoriseStepFooter;
