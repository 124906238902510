import { FC } from 'react';
import { IInvoiceFromSearch } from 'types';
import { useStoreState } from 'state';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import useInvoicesFooterActions from './useInvoicesFooterActions';
import Button from 'components/shared/Button/Button';
import { Paragraph, PermissionsChecker, Row, StaleInfo } from 'components';
import { useTheme } from 'styled-components';
import InvoicesCurrencyTotals from 'components/shared/InvoicesCurrencyTotals/InvoicesCurrencyTotals';
import { getCurrencyTotalsFromInvoices } from 'utils/invoices';
import {
  getUpdatedApprovedInvoices,
  getUpdatedSubmittedInvoices,
} from 'pages/Invoices/components/GroupedInvoicesTable/components/ViewDetailsPopup/utils';

interface IOwnProps {
  selectedInvoices: IInvoiceFromSearch[];
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
  unselectInvoice?: (invoiceId: string) => void;
  setInvoicesForAllocateFx: (invoices: IInvoiceFromSearch[]) => void;
}

const InvoicesTableFooterContent: FC<IOwnProps> = ({
  selectedInvoices,
  updateInMemoryInvoices,
  unselectInvoice,
  setInvoicesForAllocateFx,
}) => {
  const theme = useTheme();
  const {
    isUserApprover,
    hasApprovalFlow,
    user,
    userEntity,
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
  } = useStoreState((state) => state.UserState);
  const { isUpdatingPaymentRun } = useStoreState(
    (state) => state.PaymentRunsState
  );
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const { transferById } = useStoreState((state) => state.TransfersState);

  const requiredNumberOfApprovalsOnUserEntity =
    userEntity?.approvalSettings?.requiredNumberOfApprovals || 1;
  const {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();
  const {
    invoicesForApproval,
    invoicesForSubmission,
    invoicesForPayment,
    invoicesForAllocateFx,
    hasReceivableInvoices,
    shouldShowAllocatedFxButton,
    onAddToPaymentRun,
  } = useInvoicesFooterActions({
    selectedInvoices,
  });

  const currencyTotals = getCurrencyTotalsFromInvoices({
    invoices: selectedInvoices,
    transferById,
    rateContractById,
  });

  const isAddToPaymentRunDisabled =
    hasReceivableInvoices ||
    isUpdatingPaymentRun ||
    (!isAutomationPackageEnabled && !isFxManagementPackageEnabled);

  return (
    <Row justifyContent="flex-start" gap={theme.spacing.s}>
      {hasApprovalFlow && !!invoicesForSubmission.length && !!unselectInvoice && (
        <>
          <Button
            mr
            onClick={() => {
              const invoiceIds = invoicesForSubmission.map(
                (invoice) => invoice.id
              );

              updateInvoicesApprovalStatus({
                invoiceIds,
                approvalStatus: 'submitted',
              }).then(() => {
                updateInMemoryInvoices((invoices) =>
                  getUpdatedSubmittedInvoices({
                    invoices,
                    invoicesForSubmission,
                  })
                );

                invoiceIds.forEach((invoiceId) => {
                  unselectInvoice(invoiceId);
                });
              });
            }}
            disabled={isUpdatingInvoicesApprovalStatus}
            isLoading={isUpdatingInvoicesApprovalStatus}
          >
            {`Submit ${invoicesForSubmission.length} selected`}
          </Button>
        </>
      )}

      {hasApprovalFlow &&
        isUserApprover &&
        !!invoicesForApproval.length &&
        !!unselectInvoice && (
          <Button
            mr
            onClick={() => {
              const invoiceIds = invoicesForApproval.map(
                (invoice) => invoice.id
              );

              updateInvoicesApprovalStatus({
                invoiceIds,
                approvalStatus: 'approved',
              }).then(() => {
                updateInMemoryInvoices((invoices) =>
                  getUpdatedApprovedInvoices({
                    invoices,
                    invoicesForApproval,
                    requiredNumberOfApprovalsOnUserEntity,
                    userId: user?.id,
                  })
                );

                invoiceIds.forEach((invoiceId) => {
                  unselectInvoice(invoiceId);
                });
              });
            }}
            disabled={isUpdatingInvoicesApprovalStatus}
            isLoading={isUpdatingInvoicesApprovalStatus}
          >
            {`Approve ${invoicesForApproval.length} selected`}
          </Button>
        )}

      {!!invoicesForPayment.length && (
        <PermissionsChecker action="update" resource="payment_runs">
          <StaleInfo
            mode="hover"
            strategy="fixed"
            portal
            placement="top"
            infoSize="auto"
            trigger={
              <Button
                onClick={onAddToPaymentRun}
                disabled={isAddToPaymentRunDisabled}
                isLoading={isUpdatingPaymentRun}
              >
                Add to draft run
              </Button>
            }
          >
            {isAddToPaymentRunDisabled && (
              <Paragraph color="white">
                Payment automation package not available. Please check you have
                an active subscription.
              </Paragraph>
            )}
          </StaleInfo>
        </PermissionsChecker>
      )}

      {shouldShowAllocatedFxButton && (
        <Button
          onClick={() => {
            setInvoicesForAllocateFx(invoicesForAllocateFx);
          }}
          variant="secondary"
        >
          Allocate FX
        </Button>
      )}

      {!!selectedInvoices.length && (
        <Row flex={1} justifyContent="flex-end">
          <InvoicesCurrencyTotals
            title="Totals:"
            currencyTotals={currencyTotals}
          />
        </Row>
      )}
    </Row>
  );
};

export default InvoicesTableFooterContent;
