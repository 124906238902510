import { Button, Paragraph, Popup, Row, Title } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';
import { IPastPerformanceItem } from 'types';
import { parseIntoCurrencyStringWithSymbol, parseRate } from 'utils';
import { getValueColor } from '../../utils';

interface IOwnProps {
  record: IPastPerformanceItem;
  onClose: () => void;
}

const FxCostBreakdown: FC<IOwnProps> = ({ record, onClose }) => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  return (
    <Popup
      HeaderContent={<Title variant="h2">FX Cost</Title>}
      FooterContent={
        <Row>
          <Button onClick={onClose}>Close</Button>
        </Row>
      }
      onClose={onClose}
    >
      <Paragraph mb>
        We estimate FX conversion costs using the daily system rates:
      </Paragraph>

      <table>
        <thead>
          <th />
          <th>
            <Paragraph variant="bold" textAlign="end">
              FX rate
            </Paragraph>
          </th>
          <th>
            <Paragraph variant="bold" textAlign="end">
              {entityCurrencyCode} value
            </Paragraph>
          </th>
        </thead>
        <tr>
          <td>
            <Paragraph>Payment FX rate</Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseRate(record.payRate)}
            </Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseIntoCurrencyStringWithSymbol(
                Math.abs(record.amountPaidWithPayRate ?? 0),
                currencyByCode(entityCurrencyCode)?.symbol
              )}
            </Paragraph>
          </td>
        </tr>
        <tr>
          <td>
            <Paragraph>Same date system FX rate</Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseRate(1 / (record.paidDateSystemRate ?? 1))}
            </Paragraph>
          </td>
          <td>
            <Paragraph variant="bold" textAlign="end">
              {parseIntoCurrencyStringWithSymbol(
                Math.abs(record.amountPaidWithSystemRate ?? 0),
                currencyByCode(entityCurrencyCode)?.symbol
              )}
            </Paragraph>
          </td>
        </tr>

        <tr>
          <td>
            <Paragraph>FX gain/loss from FX moves</Paragraph>
          </td>
          <td />
          <td>
            <Paragraph
              variant="bold"
              textAlign="end"
              color={getValueColor(record.fxCost)}
            >
              {parseIntoCurrencyStringWithSymbol(
                record.fxCost,
                currencyByCode(entityCurrencyCode)?.symbol
              )}{' '}
              ({((record.fxCostPercentage ?? 0) * 100)?.toPrecision(3)}%)
            </Paragraph>
          </td>
        </tr>
      </table>
    </Popup>
  );
};

export default FxCostBreakdown;
