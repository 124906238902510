import { FC } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { Paragraph, StaleInput } from 'components';
import {
  ReAuthenticateWrapper,
  ReAuthenticateButtonsWrapper,
  StalePasswordField,
} from './StaleReAuthenticate.styles';
import { useReAuthenticate } from 'hooks';
import { ERROR_MESSAGES } from 'variables';

interface Props {
  control: Control;
  errors?: FieldErrors<{ password: string }>;
  withLabel?: boolean;
}

const StaleReAuthenticate: FC<Props> = ({
  children,
  control,
  errors,
  withLabel = true,
}) => {
  const theme = useTheme();
  const { isPasswordProvider } = useReAuthenticate();

  return (
    <ReAuthenticateWrapper>
      {isPasswordProvider && withLabel && (
        <Paragraph variant="bold" mb mbValue={theme.spacing.xs}>
          Re-enter your password to authorise the transfer
        </Paragraph>
      )}

      <ReAuthenticateButtonsWrapper>
        {isPasswordProvider && (
          <StalePasswordField>
            <StaleInput
              control={control}
              view="moving"
              name="password"
              id="password"
              label="Password"
              type="password"
              defaultValue={''}
              rules={{
                required: ERROR_MESSAGES.requiredField,
              }}
              error={errors?.password?.message}
            />
          </StalePasswordField>
        )}
        {children}
      </ReAuthenticateButtonsWrapper>
    </ReAuthenticateWrapper>
  );
};

export default StaleReAuthenticate;
