import { IRecipient } from './../types/recipients';
import {
  IIntegrationSettingsAccount,
  IIntegrationSettingsAll,
  IInvoice,
  IInvoiceFromSearch,
} from 'types';
import {
  IAccountData,
  IIntegrationStatusSummary,
  IAvailableIntegration,
} from 'types/integrations';
import { IFormBankTransferXeroAccount } from 'components/shared/StepAccountsFirst/components/StepAccountsForm/StepAccountsForm';

export const generateIntegrationEngineBankFeesAccountData = (
  account: IIntegrationSettingsAccount,
  code?: string
): IAccountData => {
  const { name } = account;
  const nameValue = !!code ? `${name} (${code})` : name;
  return {
    id: nameValue,
    value: account,
    name: nameValue,
  };
};

const getIntegrationSettingsAccountDocId = (
  account: IIntegrationSettingsAccount
) => {
  const { type, currency } = account;
  if (
    type === 'bankTransferAccount' ||
    type === 'currencyAccount' ||
    type === 'journal'
  ) {
    return `${type}-${currency}`;
  }

  return type;
};

export const generateIntegrationSettingsAccountDocIdsToDelete = ({
  savedAccounts,
  updatedAccounts,
}: {
  savedAccounts?: IIntegrationSettingsAccount[];
  updatedAccounts?: IIntegrationSettingsAccount[];
}) => {
  const savedAccountsDocIds = savedAccounts?.map((account) =>
    getIntegrationSettingsAccountDocId(account)
  );
  const updatedAccountsDocIds = updatedAccounts?.map((account) =>
    getIntegrationSettingsAccountDocId(account)
  );

  return (
    savedAccountsDocIds?.filter(
      (docId) => !updatedAccountsDocIds?.includes(docId)
    ) ?? []
  );
};

export const generateIntegrationSettingsBankTransferAccountsToSave = (
  formBankTransferAccounts: IFormBankTransferXeroAccount[]
) => {
  const dataToReturn = [];

  if (formBankTransferAccounts.length > 0) {
    for (const formBankTransferAccount of formBankTransferAccounts) {
      if (formBankTransferAccount.currency && formBankTransferAccount.account) {
        const {
          id,
          name,
          code,
          currency,
          category,
        } = formBankTransferAccount.account;
        const bankTransferAccount: IIntegrationSettingsAccount = {
          id,
          name,
          code,
          currency,
          type: 'bankTransferAccount',
          category,
        };
        dataToReturn.push(bankTransferAccount);
      }
    }
  }

  return dataToReturn;
};

interface IIsContactIdsMatchWithInvoiceFromSearchParams {
  invoice: IInvoiceFromSearch;
  recipient: IRecipient;
}
export const isSourceSystemContactIdsNotMatchWithInvoiceFromSearch = ({
  invoice,
  recipient,
}: IIsContactIdsMatchWithInvoiceFromSearchParams) =>
  invoice.externalRefsSourceSystemContactId &&
  invoice.externalRefsSourceSystemContactId !==
    recipient.externalRefs?.sourceSystemId;

interface IIsContactIdsMatchWithInvoiceParams {
  invoice: IInvoice;
  recipient: IRecipient;
}

export const isExternalContactIdsNotMatchWithInvoice = ({
  invoice,
  recipient,
}: IIsContactIdsMatchWithInvoiceParams) =>
  invoice.externalRefs?.sourceSystemContactId &&
  invoice.externalRefs.sourceSystemContactId !==
    recipient.externalRefs?.sourceSystemId;

// TODO: We need to change this to use the IE Settings endpoint
export const getExcludedCurrenciesFromIntegrations = (
  integrationEngineSettings: IIntegrationSettingsAll
) => {
  const currenciesToExclude: string[] = [];

  if (integrationEngineSettings?.settings?.excludedCurrencies) {
    currenciesToExclude.push(
      ...integrationEngineSettings.settings.excludedCurrencies
    );
  }

  return currenciesToExclude;
};

export const isIntegrationConnected = ({
  integrationsSummary,
  integrationDoc,
}: {
  integrationsSummary: IIntegrationStatusSummary | null;
  integrationDoc: IAvailableIntegration;
}) =>
  integrationsSummary?.system === integrationDoc.system &&
  !!integrationsSummary?.connected;
